import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase";
const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const getSensors = async (facilityId) => {
  let sensors;
  if (!ENV || !VERSION) {
    return Promise.reject(new Error("Environment or Version not configured"));
  }

  try {
    const sensorsRef = collection(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "sensors"
    );
    const activeQuery = query(sensorsRef, where("active", "==", true));
    await getDocs(activeQuery)
      .then((querySnapshot) => {
        sensors = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
      })
      .catch((error) => {
        console.error("Error fetching sensors:", error);
      });
    return sensors;
  } catch (error) {
    console.error("Error creating sensors query:", error);
  }
};

export default getSensors;
