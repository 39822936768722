import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";

import BasicLanguage from "../languages/Basic";
import languageState from "../../recoil/atoms/languageState";

import { useParams } from "react-router";

import { db } from "../utils/firebase";
import { addDoc, collection } from "firebase/firestore";
import { useRecoilValue } from "recoil";
const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const CreateTask = (props) => {
  const params = useParams();
  const facilityId = params.facilityId;
  const language = useRecoilValue(languageState);
  const taskType = props.taskType;
  const existingTasks = props.existingTasks;

  const [modal, setModal] = React.useState(false);

  const createTask = (e) => {
    e.preventDefault();
    const task = e.target.request.value;

    if (
      existingTasks.some((x) => x.name === task && !x.inProgress) ||
      existingTasks.some((x) => x.name === task && x.inProgress)
    ) {
      alert(BasicLanguage.alert.alreadyExists[language]);
      return false;
    }
    if (existingTasks.map((x) => x.name).indexOf(task) > -1) {
      alert(BasicLanguage.alert.alreadyExists[language]);
      return false;
    }

    if (task) {
      addDoc(collection(db, ENV, VERSION, "facilities", facilityId, taskType), {
        name: task,
        completed: false,
      })
        .then(() => {
          props.fetch(Math.random());
          handleModalClose();
        })
        .catch((e) => console.error(e));
    } else {
    }
  };

  const handleModalClose = (e) => {
    setModal(false);
  };
  return (
    <>
      <Button variant="outlined" onClick={() => setModal(true)}>
        {BasicLanguage.qr.add[language]}
      </Button>

      <Dialog open={modal} onClose={handleModalClose}>
        <Box component="form" onSubmit={createTask}>
          <DialogTitle> {BasicLanguage.qrAdd.addTask[language]}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="request"
              label={BasicLanguage.qr.request[language]}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose} color="error">
              {BasicLanguage.qrAdd.cancel[language]}
            </Button>
            <Button type="submit" variant="contained">
              {BasicLanguage.qrAdd.ok[language]}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};
export default CreateTask;
