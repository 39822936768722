import * as React from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import BasicLanguage from "../components/languages/Basic";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SBXStyles from "../components/utils/Styles";
import { useParams } from "react-router";
import CommonError from "./CommonError";
import { Link } from "react-router-dom";
import SensorCurrentValue from "../components/parts/SensorCurrentValue";
import moment from "moment";
import "moment-timezone";
import "moment/locale/ja";
import Typography from "../components/parts-ui/Typography";
import { db } from "../components/utils/firebase";
import {
  doc,
  collection,
  addDoc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import languageState from "../recoil/atoms/languageState";
import { useRecoilValue } from "recoil";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getNameByUid } from "../components/utils/getUser";
import facilityState from "../recoil/atoms/facilityState";
import triggerComparison from "../components/utils/triggerComparison";
import BatteryStatus from "../components/parts/BatteryStatus";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SensorResetTime from "../components/parts/SensorResetTime";
import { TimeZoneContext } from "../components/utils/setTimeZoneContext";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function SensorDetail() {
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facilityId = params.facilityId;
  const sensorId = params.sensorId;

  const [sensor, setSensor] = React.useState({});
  const [nameEditMode, setNameEditMode] = React.useState(false);
  const [newName, setNewName] = React.useState(0);
  const [newProcess, setNewProcess] = React.useState("");
  const [processEditMode, setProcessEditMode] = React.useState(false);
  const [newBeaconHolder, setNewBeaconHolder] = React.useState("");
  const [beaconEditMode, setBeaconEditMode] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [resetTime, setResetTime] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const resetSensorValue = async (facilityId, sensorId) => {
    const data = {
      createdAt: serverTimestamp(),
      deviceId: sensorId,
      value: null,
      reset: true,
    };
    await addDoc(
      collection(db, ENV, VERSION, "facilities", facilityId, "sensorHistory"),
      data
    ).catch((e) => console.error(e));
  };

  const getData = React.useCallback(() => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId, "sensors", sensorId))
      .then((sensorDocSnap) => {
        setSensor({ ...sensorDocSnap.data(), id: sensorDocSnap.id });
        setNewBeaconHolder(
          sensorDocSnap.data().beaconHolder ||
            BasicLanguage.sensor.maintain[language]
        ); // Adjusted this line
      })
      .catch((e) => console.error(e));
  }, [facilityId, sensorId]);

  React.useEffect(() => {
    getData();
    getDataUser();
  }, [getData]);

  const submitName = (e) => {
    e.preventDefault();
    updateDoc(
      doc(db, ENV, VERSION, "facilities", facilityId, "sensors", sensorId),
      { name: newName }
    )
      .then(() => {
        getData();
        setNameEditMode(false);
      })
      .catch((e) => console.error(e));
  };

  const toNameEditMode = () => {
    setNewName(sensor ? sensor.name : "");
    setNameEditMode(true);
  };

  const cancelNameEdit = () => {
    setNewName("");
    setNameEditMode(false);
  };

  const changeNewName = (e) => {
    setNewName(e.target.value);
  };

  const toProcessEditMode = () => {
    setNewProcess(sensor ? sensor.process : 0);
    setProcessEditMode(true);
  };

  const submitProcess = (e) => {
    e.preventDefault();
    updateDoc(
      doc(db, ENV, VERSION, "facilities", facilityId, "sensors", sensorId),
      { process: newProcess }
    )
      .then(() => {
        getData();
        setProcessEditMode(false);
      })
      .catch((e) => console.error(e));
  };

  const cancelProcessEdit = () => {
    setNewProcess("");
    setProcessEditMode(false);
  };

  const toBeaconEditMode = () => {
    setNewBeaconHolder(sensor?.beaconHolder ? sensor.beaconHolder : 0);
    setBeaconEditMode(true);
  };

  const submitBeacon = (e) => {
    e.preventDefault();
    updateDoc(
      doc(db, ENV, VERSION, "facilities", facilityId, "sensors", sensorId),
      { beaconHolder: newBeaconHolder }
    )
      .then(() => {
        getData();
        setBeaconEditMode(false);
      })
      .catch((e) => console.error(e));
  };

  const cancelBeaconEdit = () => {
    setNewBeaconHolder(
      sensor?.beaconHolder || BasicLanguage.sensor.maintain[language]
    );
    setBeaconEditMode(false);
  };
  const getDataUser = () => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId))
      .then((x) => {
        const _users = []
          .concat(
            Object.keys(x.data().admin).map((y) => {
              return { ...x.data().admin[y], role: 0 };
            })
          )
          .concat(
            Object.keys(x.data().users).map((y) => {
              return { ...x.data().users[y], role: 1 };
            })
          );
        setUsers(_users);
      })
      .catch((e) => console.error(e));
  };

  React.useEffect(() => {
    const fetchResetTime = async () => {
      try {
        setLoading(true);
        const q = query(
          collection(
            db,
            ENV,
            VERSION,
            "facilities",
            facilityId,
            "sensorHistory"
          ),
          orderBy("createdAt", "desc"),
          where("deviceId", "==", sensorId),
          where("reset", "==", true),
          limit(1)
        );

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            setResetTime(data.createdAt.toDate());
          });
        } else {
          setResetTime(null);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching reset time:", error);
        setLoading(false);
      }
    };

    fetchResetTime();
  }, [facilityId, sensorId]);

  return (
    <>
      <Box component="main" sx={SBXStyles.mainContainer}>
        {sensor ? (
          <Box>
            <Typography className="pageTitle" variant="h4">
              <IconButton LinkComponent={Link} to={"../sensors"}>
                <ArrowBackIcon />
              </IconButton>
              {sensor.name}
            </Typography>
            <Box sx={SBXStyles.mainBox}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "30%" }}>
                      {BasicLanguage.sensor.table.item[language]}
                    </TableCell>
                    <TableCell>
                      {BasicLanguage.sensor.table.content[language]}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>{sensor.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.sensor.table.type[language]}
                    </TableCell>
                    <TableCell>{sensor.type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.sensor.table.name[language]}
                    </TableCell>
                    <TableCell>
                      {nameEditMode ? (
                        <>
                          <form onSubmit={submitName}>
                            <TextField
                              variant="standard"
                              onChange={changeNewName}
                              defaultValue={sensor.name}
                              autoFocus={true}
                            />
                            <IconButton type="submit">
                              <CheckCircleIcon color="success" />
                            </IconButton>
                            <IconButton onClick={cancelNameEdit}>
                              <CancelIcon color="error" />
                            </IconButton>
                          </form>
                        </>
                      ) : (
                        <>
                          {sensor.name}
                          <IconButton sx={{ ml: 1 }} onClick={toNameEditMode}>
                            <EditIcon sx={{ fontSize: "0.7em" }} />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.sensor.table.coordinates[language]}
                    </TableCell>
                    <TableCell>
                      <Box>{sensor.coordinates?.[0]}</Box>
                      <Box>{sensor.coordinates?.[1]}</Box>
                    </TableCell>
                  </TableRow>
                  {sensor.type !== "webiot_ibeacon" && (
                    <TableRow>
                      <TableCell>
                        {BasicLanguage.sensor.table.battery[language]}
                      </TableCell>
                      <TableCell>
                        <BatteryStatus
                          facilityId={facilityId}
                          sensorId={sensorId}
                          sensorType={sensor.type}
                        />
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell>
                      {BasicLanguage.sensor.table.value[language]}
                    </TableCell>
                    <TableCell>
                      <SensorCurrentValue
                        facilityId={facilityId}
                        sensorId={sensor.id}
                        sensorProcess={sensor.process}
                        reloadButton={true}
                        language={language}
                      />
                    </TableCell>
                  </TableRow>

                  {sensor.type !== "webiot_ibeacon" && (
                    <TableRow>
                      <TableCell>
                        {BasicLanguage.sensor.process[language]}
                      </TableCell>
                      <TableCell>
                        {processEditMode ? (
                          <>
                            <form onSubmit={submitProcess}>
                              <Select
                                value={newProcess}
                                onChange={(e) => setNewProcess(e.target.value)}
                              >
                                <MenuItem value={0}>
                                  {BasicLanguage.sensor.maintain[language]}
                                </MenuItem>
                                <MenuItem value={1}>
                                  {BasicLanguage.sensor.increase[language]}
                                </MenuItem>
                                <MenuItem value={2}>
                                  {BasicLanguage.sensor.decrease[language]}
                                </MenuItem>
                              </Select>
                              <IconButton type="submit">
                                <CheckCircleIcon color="success" />
                              </IconButton>
                              <IconButton onClick={cancelProcessEdit}>
                                <CancelIcon color="error" />
                              </IconButton>
                            </form>
                          </>
                        ) : (
                          <>
                            {
                              [
                                BasicLanguage.sensor.maintain[language],
                                BasicLanguage.sensor.increase[language],
                                BasicLanguage.sensor.decrease[language],
                              ][sensor.process]
                            }
                            <IconButton
                              sx={{ ml: 1 }}
                              onClick={toProcessEditMode}
                            >
                              <EditIcon sx={{ fontSize: "0.7em" }} />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                  {sensor.process !== 0 && sensor.type !== "webiot_ibeacon" && (
                    <TableRow>
                      <TableCell>
                        {BasicLanguage.sensor.reset[language]}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            resetSensorValue(facilityId, sensor.id);
                          }}
                        >
                          {BasicLanguage.sensor.reset[language]}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                  {sensor.type !== "webiot_ibeacon" && (
                    <TableRow>
                      <TableCell>
                        {BasicLanguage.sensor.currentResetTime[language]}
                      </TableCell>
                      <TableCell>
                        <SensorResetTime sensorId={sensor.id} />
                      </TableCell>
                    </TableRow>
                  )}
                  {sensor.type === "webiot_ibeacon" && (
                    <TableRow>
                      <TableCell>
                        {BasicLanguage.sensor.iBeaconHolder[language]}
                      </TableCell>
                      <TableCell>
                        {beaconEditMode ? (
                          <>
                            <form onSubmit={submitBeacon}>
                              <Select
                                value={newBeaconHolder}
                                onChange={(e) =>
                                  setNewBeaconHolder(e.target.value)
                                }
                              >
                                <MenuItem value={0}>
                                  {BasicLanguage.sensor.maintain[language]}
                                </MenuItem>
                                {users.map((x) => (
                                  <MenuItem key={x.uid} value={x.uid}>
                                    {x.displayName}
                                  </MenuItem>
                                ))}
                              </Select>
                              <IconButton type="submit">
                                <CheckCircleIcon color="success" />
                              </IconButton>
                              <IconButton onClick={cancelBeaconEdit}>
                                <CancelIcon color="error" />
                              </IconButton>
                            </form>
                          </>
                        ) : (
                          <>
                            {newBeaconHolder
                              ? users.find(
                                  (user) => user.uid === newBeaconHolder
                                )?.displayName ||
                                BasicLanguage.sensor.maintain[language]
                              : ""}
                            <IconButton
                              sx={{ ml: 1 }}
                              onClick={toBeaconEditMode}
                            >
                              <EditIcon sx={{ fontSize: "0.7em" }} />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              {sensor.type !== "webiot_ibeacon" && (
                <Box sx={{ m: 4 }}>
                  <Typography>
                    {BasicLanguage.sensor.process[language]}
                    <br />
                    {BasicLanguage.sensor.increase[language]}：
                    {BasicLanguage.sensor.increaseExplanation[language]}
                    <br />
                    {BasicLanguage.sensor.decrease[language]}：
                    {BasicLanguage.sensor.decreaseExplanation[language]}
                    <br />
                    {BasicLanguage.sensor.maintain[language]}：
                    {BasicLanguage.sensor.maintainExplanation[language]}
                    <br />
                    <br />
                  </Typography>
                </Box>
              )}
            </Box>

            {sensor.type !== "webiot_ibeacon" && (
              <Box sx={SBXStyles.mainBox}>
                <Stack direction="row" justifyContent="space-between">
                  <Box>
                    <Typography variant="h5">
                      {BasicLanguage.sensor.triggers.triggers[language]}
                    </Typography>
                    <Typography>
                      {BasicLanguage.sensor.triggerNote[language]}
                    </Typography>
                  </Box>
                  <Button component={Link} to={"./trigger"}>
                    {BasicLanguage.common.add[language]}
                  </Button>
                </Stack>
                <Triggers />
              </Box>
            )}
            <Box sx={SBXStyles.mainBox}>
              <Stack>
                <Box>
                  <Typography variant="h5">
                    {BasicLanguage.sensor.history[language]}
                  </Typography>
                </Box>
                {sensor.type !== "webiot_ibeacon" ? (
                  <History language={language} />
                ) : (
                  <BeaconHistory language={language} />
                )}
              </Stack>
            </Box>
          </Box>
        ) : (
          <CommonError></CommonError>
        )}
      </Box>
    </>
  );
}

const BeaconHistory = (props) => {
  const params = useParams();
  const sensorId = params.sensorId;
  const facilityId = params.facilityId;
  const [zeroFilter, setZeroFilter] = React.useState(true);
  const [history, setHistory] = React.useState([]);
  const displayHistory = history.filter((historyEntry) =>
    zeroFilter ? historyEntry.value !== 0 : true
  );
  const [date, setDate] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const language = props.language;
  const { currentTimeZoneName } = React.useContext(TimeZoneContext);
  const [users, setUsers] = React.useState([]);

  const getDataUser = () => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId))
      .then((x) => {
        const _users = []
          .concat(
            Object.keys(x.data().admin).map((y) => {
              return { ...x.data().admin[y], role: 0 };
            })
          )
          .concat(
            Object.keys(x.data().users).map((y) => {
              return { ...x.data().users[y], role: 1 };
            })
          );
        setUsers(_users);
      })
      .catch((e) => console.error(e));
  };

  const download = () => {
    let csvString = `${BasicLanguage.sensor.value[language]},${BasicLanguage.sensor.date[language]},${BasicLanguage.sensor.iBeaconHolder[language]}\n`;
    csvString += displayHistory
      .map(
        (displayHistoryEntry) =>
          `${displayHistoryEntry.value},${moment(
            displayHistoryEntry.createdAt.seconds * 1000
          )
            .tz(currentTimeZoneName)
            .format("YYYY-MM-DD HH:mm:ss")},${
            displayHistoryEntry.beaconHolder
              ? users.find(
                  (user) => user.uid === displayHistoryEntry.beaconHolder
                )?.displayName || BasicLanguage.sensor.maintain[language]
              : ""
          }`
      )
      .join("\n");

    const file_name = `${facilityId}_${sensorId}.csv`;
    //CSVのバイナリデータを作る
    let blob = new Blob([csvString], { type: "text/csv" });
    let uri = URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.download = file_name;
    link.href = uri;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const changeDate = (newDate) => {
    setDate(newDate);
    getData(newDate);
  };
  const resetDate = () => {
    setDate(null);
    getData();
  };
  const getData = React.useCallback(
    (targetDate) => {
      setLoading(true);
      let q;
      if (targetDate) {
        const start = targetDate.startOf("day").toDate();
        const end = moment(targetDate).add(1, "d").startOf("day").toDate();
        q = query(
          collection(
            db,
            ENV,
            VERSION,
            "facilities",
            facilityId,
            "sensorHistory"
          ),
          orderBy("createdAt", "desc"),
          where("deviceId", "==", sensorId),
          where("dataType", "==", "data"),
          where("createdAt", ">=", start),
          where("createdAt", "<", end)
        );
      } else {
        q = query(
          collection(
            db,
            ENV,
            VERSION,
            "facilities",
            facilityId,
            "sensorHistory"
          ),
          orderBy("createdAt", "desc"),
          where("deviceId", "==", sensorId),
          limit(100),
          where("dataType", "==", "data")
        );
      }
      getDocs(q)
        .then((querysnapshot) => {
          setLoading(false);
          setHistory(
            querysnapshot.docs.map((doc) => {
              return { ...doc.data(), id: doc.id };
            })
          );
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
        });
    },
    [facilityId, sensorId]
  );

  React.useEffect(() => {
    getData();
    getDataUser();
  }, [getData]);

  React.useEffect(() => {
    moment.locale(language);
  }, [language]);

  return (
    <>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button variant="outlined" onClick={resetDate}>
          {BasicLanguage.sensor.recent[language]}
        </Button>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={language}
        >
          <DatePicker
            disableFuture={true}
            value={date}
            onChange={changeDate}
            format={BasicLanguage.common.date.datePicker.long[language]}
          />
        </LocalizationProvider>
      </Stack>

      <TableContainer
        sx={{
          mt: 2,
          maxHeight: "400px",
          "& .MuiTableCell-head": {
            backgroundColor: "rgba(220,220,220,1)",
            fontWeight: "bold",
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{BasicLanguage.sensor.value[language]}</TableCell>
              <TableCell>{BasicLanguage.sensor.date[language]}</TableCell>
              <TableCell>
                {BasicLanguage.sensor.iBeaconHolder[language]}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={2}>
                  <Box
                    sx={{
                      display: "flex",
                      height: 200,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              displayHistory.map((historyEntry) => {
                return (
                  <TableRow key={historyEntry.id}>
                    <TableCell>{historyEntry.value}</TableCell>
                    <TableCell>
                      {moment(historyEntry.createdAt.seconds * 1000)
                        .tz(currentTimeZoneName)
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </TableCell>
                    <TableCell>
                      {historyEntry.beaconHolder
                        ? users.find(
                            (user) => user.uid === historyEntry.beaconHolder
                          )?.displayName ||
                          BasicLanguage.sensor.maintain[language]
                        : ""}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
            {}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        {history.length > 0 && (
          <Button onClick={download} variant="outlined">
            {BasicLanguage.common.download[language]}
          </Button>
        )}
      </Stack>
    </>
  );
};

const History = (props) => {
  const params = useParams();
  const sensorId = params.sensorId;
  const facilityId = params.facilityId;
  const [zeroFilter, setZeroFilter] = React.useState(true);
  const [history, setHistory] = React.useState([]);
  const displayHistory = history.filter((historyEntry) =>
    zeroFilter ? historyEntry.value !== 0 : true
  );
  const [date, setDate] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const language = props.language;
  const { currentTimeZoneName } = React.useContext(TimeZoneContext);

  const download = () => {
    let csvString = "Value,Date\n";

    csvString += displayHistory
      .map(
        (displayHistoryEntry) =>
          `${displayHistoryEntry.value},${moment(
            displayHistoryEntry.createdAt.seconds * 1000
          )
            .tz(currentTimeZoneName)
            .format("YYYY-MM-DD HH:mm:ss")}`
      )
      .join("\n");

    const file_name = `${facilityId}_${sensorId}.csv`;

    //CSVのバイナリデータを作る
    let blob = new Blob([csvString], { type: "text/csv" });
    let uri = URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.download = file_name;
    link.href = uri;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };
  const changeDate = (newDate) => {
    setDate(newDate);
    getData(newDate);
  };
  const resetDate = () => {
    setDate(null);
    getData();
  };
  const getData = React.useCallback(
    (targetDate) => {
      setLoading(true);
      let q;
      if (targetDate) {
        const start = targetDate.startOf("day").toDate();
        const end = moment(targetDate).add(1, "d").startOf("day").toDate();
        q = query(
          collection(
            db,
            ENV,
            VERSION,
            "facilities",
            facilityId,
            "sensorHistory"
          ),
          orderBy("createdAt", "desc"),
          where("deviceId", "==", sensorId),
          where("dataType", "==", "data"),
          where("createdAt", ">=", start),
          where("createdAt", "<", end)
        );
      } else {
        q = query(
          collection(
            db,
            ENV,
            VERSION,
            "facilities",
            facilityId,
            "sensorHistory"
          ),
          orderBy("createdAt", "desc"),
          where("deviceId", "==", sensorId),
          limit(100),
          where("dataType", "==", "data")
        );
      }
      getDocs(q)
        .then((querysnapshot) => {
          setLoading(false);
          setHistory(
            querysnapshot.docs.map((doc) => {
              return { ...doc.data(), id: doc.id };
            })
          );
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
        });
    },
    [facilityId, sensorId]
  );

  React.useEffect(() => {
    getData();
  }, [getData]);

  React.useEffect(() => {
    moment.locale(language);
  }, [language]);

  return (
    <>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button variant="outlined" onClick={resetDate}>
          {BasicLanguage.sensor.recent[language]}
        </Button>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={language}
        >
          <DatePicker
            disableFuture={true}
            value={date}
            onChange={changeDate}
            format={BasicLanguage.common.date.datePicker.long[language]}
          />
        </LocalizationProvider>
      </Stack>

      <TableContainer
        sx={{
          mt: 2,
          maxHeight: "400px",
          "& .MuiTableCell-head": {
            backgroundColor: "rgba(220,220,220,1)",
            fontWeight: "bold",
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{BasicLanguage.sensor.value[language]}</TableCell>
              <TableCell>{BasicLanguage.sensor.date[language]}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={2}>
                  <Box
                    sx={{
                      display: "flex",
                      height: 200,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              displayHistory.map((historyEntry) => {
                return (
                  <TableRow key={historyEntry.id}>
                    <TableCell>{historyEntry.value}</TableCell>
                    <TableCell>
                      {moment(historyEntry.createdAt.seconds * 1000)
                        .tz(currentTimeZoneName)
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
            {}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        {history.length > 0 && (
          <Button onClick={download} variant="outlined">
            {BasicLanguage.common.download[language]}
          </Button>
        )}
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={zeroFilter}
                onChange={(e) => setZeroFilter(e.target.checked)}
              />
            }
            label={BasicLanguage.sensor.zero[language]}
          />
        </Box>
      </Stack>
    </>
  );
};
const Triggers = () => {
  const params = useParams();
  const facilityId = params.facilityId;
  const sensorId = params.sensorId;
  const [triggers, setTriggers] = React.useState([]);
  const facility = useRecoilValue(facilityState);

  const language = useRecoilValue(languageState);

  const getData = React.useCallback(() => {
    getDocs(
      query(
        collection(
          db,
          ENV,
          VERSION,
          "facilities",
          facilityId,
          "sensors",
          sensorId,
          "triggers"
        )
      )
    )
      .then((querysnapshot) =>
        setTriggers(
          querysnapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          })
        )
      )
      .catch((e) => console.error(e));
  }, [facilityId, sensorId]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "15%" }}>
            {BasicLanguage.common.active[language]}
          </TableCell>
          <TableCell sx={{ width: "15%" }}>
            {BasicLanguage.sensor.trigger.threshold[language]}
          </TableCell>
          <TableCell sx={{ width: "15%" }}>
            {BasicLanguage.sensor.trigger.operator[language]}
          </TableCell>
          <TableCell>
            {BasicLanguage.sensor.trigger.assignee[language]}
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {triggers.map((trigger) => (
          <TableRow
            key={trigger.id}
            sx={{
              "& .MuiTableCell-body": { color: trigger.active ? "" : "grey" },
            }}
          >
            <TableCell>
              {trigger.active
                ? BasicLanguage.common.active[language]
                : BasicLanguage.common.inactive[language]}
            </TableCell>
            <TableCell>{trigger.statements.threshold}</TableCell>
            <TableCell>
              {triggerComparison[trigger.statements.comparison][language]}
            </TableCell>
            <TableCell>
              {trigger.address.map((addressee) => (
                <Typography key={addressee}>
                  {getNameByUid(facility, addressee)}
                </Typography>
              ))}
            </TableCell>
            <TableCell>
              <IconButton LinkComponent={Link} to={`./trigger/${trigger.id}`}>
                <SettingsOutlinedIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
