import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import SBXStyles from "../../utils/Styles";
import JanitorCoverageChart from "./JanitorCoverageChart";
import moment from "moment";
import CleaningRequestChart from "./CleaningRequestChart";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { useParams } from "react-router";
import "moment/locale/ja";
import RobotFloorCoverageChart from "./RobotFloorCoverageChart";
import PropTypes from "prop-types";
import OnDemandCleaningChart from "./OnDemandCleaningChart";
import DashboardControls from "./shared/DashboardControls";
import { TimeZoneContext } from "../../utils/setTimeZoneContext";
import PlaceOperationTime from "./shared/PlaceOperationTime";
import {
  calculateHoursWorkedByPerson,
  calculateHoursWorkedByPlace,
} from "../../utils/BeconSignals";
import OperationTimeByPerson from "./shared/OperationTimeByPerson";
import getSensors from "../../utils/getSensors";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function OperationKpi({
  taskReport,
  robotFloorCoverage,
  janitorCoverage,
  onDemandCleaningResponse,
  cleaningRequestResponse,
}) {
  const [date, setDate] = useState(moment());
  const [dateDisplay, setDateDisplay] = useState("day");
  const [janitorData, setJanitorData] = useState([]);
  const [qrRequestData, setQrRequestData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [taskDataPerson, setTaskDataPerson] = useState([]);
  const [sensorRequestData, setSensorRequestData] = useState([]);
  const [robotFloorRequestData, setRobotFloorRequestData] = useState([]);
  const params = useParams();
  const facilityId = params.facilityId;
  const { currentTimeZoneName } = useContext(TimeZoneContext);
  const getJanitorActivity = useCallback(() => {
    getDocs(
      query(
        collection(
          db,
          ENV,
          VERSION,
          "facilities",
          facilityId,
          "activityHistory"
        ),
        where("createdAt", ">=", moment(date).startOf("month").toDate()),
        where("createdAt", "<=", moment(date).endOf("month").toDate()),
        where("status", "==", "1")
      )
    )
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return {
            createdAt: moment(doc.data().createdAt.toDate()).tz(
              currentTimeZoneName
            ),
          };
        });
        setJanitorData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [facilityId, date]);

  const getQrRequestData = useCallback(() => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "notify"),
        where("createdAt", ">=", moment(date).startOf("month").toDate()),
        where("createdAt", "<=", moment(date).endOf("month").toDate()),
        where("type", "==", "QR")
      )
    )
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return {
            completed: !!doc.data().completed,
            createdAt: moment(doc.data().createdAt.toDate()).tz(
              currentTimeZoneName
            ),
          };
        });
        setQrRequestData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [facilityId, date]);

  const getSensorRequestData = useCallback(() => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "notify"),
        where("createdAt", ">=", moment(date).startOf("month").toDate()),
        where("createdAt", "<=", moment(date).endOf("month").toDate()),
        where("type", "==", "SENSOR")
      )
    )
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return {
            completed: !!doc.data().completed,
            createdAt: moment(doc.data().createdAt.toDate()).tz(
              currentTimeZoneName
            ),
          };
        });
        setSensorRequestData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [facilityId, date]);

  const getTaskData = useCallback(() => {
    const startDate = moment(date).startOf("month").toDate();
    const endDate = moment(date).endOf("month").toDate();
    getSensors(facilityId) // Fetch active sensors
      .then((activeSensors) => {
        const activeSensorIds = activeSensors.map((sensor) => sensor.id); // Extract sensor IDs

        const sensorHistoryQuery = query(
          collection(
            db,
            ENV,
            VERSION,
            "facilities",
            facilityId,
            "sensorHistory"
          ),
          where("createdAt", ">=", startDate),
          where("createdAt", "<=", endDate),
          where("deviceType", "==", "webiot_ibeacon"),
          where("deviceId", "in", activeSensorIds), // Filter by active sensors
          orderBy("createdAt", "desc")
        );

        return getDocs(sensorHistoryQuery);
      })
      .then((snapshot) => {
        const rawData = snapshot.docs.map((doc) => ({
          beaconHolder: doc.data().beaconHolder,
          deviceId: doc.data().deviceId,
          place: doc.data().value,
          createdAt: moment(doc.data().createdAt.toDate())
            .tz(currentTimeZoneName)
            .toDate(),
        }));

        const processedData = calculateHoursWorkedByPlace(rawData);
        setTaskData(processedData);
      })
      .catch((err) => {
        console.error("Error fetching task data:", err);
      });
  }, [facilityId, date]);

  const getTaskDataPeopleOperationTime = useCallback(() => {
    const startDate = moment(date).startOf("month").toDate();
    const endDate = moment(date).endOf("month").toDate();

    getSensors(facilityId)
      .then((activeSensors) => {
        const activeSensorIds = activeSensors.map((sensor) => sensor.id);

        const sensorHistoryQuery = query(
          collection(
            db,
            ENV,
            VERSION,
            "facilities",
            facilityId,
            "sensorHistory"
          ),
          where("createdAt", ">=", startDate),
          where("createdAt", "<=", endDate),
          where("deviceType", "==", "webiot_ibeacon"),
          where("deviceId", "in", activeSensorIds),
          orderBy("createdAt", "desc")
        );
        return getDocs(sensorHistoryQuery);
      })
      .then((snapshot) => {
        const rawData = snapshot.docs.map((doc) => ({
          deviceId: doc.data().deviceId,
          place: doc.data().value,
          beaconHolder: doc.data().beaconHolder,
          createdAt: moment(doc.data().createdAt.toDate())
            .tz(currentTimeZoneName)
            .toDate(),
        }));

        const personData = calculateHoursWorkedByPerson(rawData);

        setTaskDataPerson(personData);
      })
      .catch((err) => {
        console.error("Error fetching task data:", err);
      });
  }, [facilityId, date]);

  const getRobotFloorCoverageRequestData = useCallback(() => {
    const queryRef = query(
      collection(
        db,
        ENV,
        VERSION,
        "facilities",
        facilityId,
        "robotFloorCoverage"
      ),
      orderBy("createdAt", "desc")
    );

    getDocs(queryRef)
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          createdAt: moment(doc.data().createdAt.toDate()).tz(
            currentTimeZoneName
          ),
        }));
        data = data.filter((item) =>
          moment(item.createdAt).isSame(moment(date), "month")
        );

        setRobotFloorRequestData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [facilityId, date]);

  useEffect(() => {
    getTaskDataPeopleOperationTime();
    getTaskData();
    getJanitorActivity();
    getSensorRequestData();
    getQrRequestData();
    getRobotFloorCoverageRequestData();

    console.log("loops 1");
  }, [
    getTaskDataPeopleOperationTime,
    getTaskData,
    getJanitorActivity,
    getQrRequestData,
    getSensorRequestData,
    getRobotFloorCoverageRequestData,
  ]);

  return (
    <Box
      sx={SBXStyles.mainBox}
      id="operation-kpi"
      className="dashboard-download-all"
    >
      <Stack
        justifyContent="space-between"
        sx={{ width: "100%" }}
        direction="row"
      >
        <Typography
          variant="h5"
          sx={{ display: "block", textAlign: "", mb: 3, fontWeight: 700 }}
        >
          Operation KPI
        </Typography>

        <DashboardControls
          date={date}
          setDate={setDate}
          dateDisplay={dateDisplay}
          setDateDisplay={setDateDisplay}
          elementId="operation-kpi"
        />
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          paddingBottom: "2rem",
        }}
      >
        {(robotFloorCoverage || janitorCoverage) && (
          <>
            {robotFloorCoverage && (
              <RobotFloorCoverageChart
                date={date}
                dateDisplay={dateDisplay}
                robotData={robotFloorRequestData}
              />
            )}

            {janitorCoverage && (
              <JanitorCoverageChart
                date={date}
                dateDisplay={dateDisplay}
                janitorData={janitorData}
              />
            )}
          </>
        )}
        {(onDemandCleaningResponse || cleaningRequestResponse) && (
          <>
            {onDemandCleaningResponse && (
              <OnDemandCleaningChart
                date={date}
                dateDisplay={dateDisplay}
                requestData={sensorRequestData}
              />
            )}
            {cleaningRequestResponse && (
              <CleaningRequestChart
                date={date}
                dateDisplay={dateDisplay}
                requestData={qrRequestData}
              />
            )}
          </>
        )}

        {taskReport && (
          <>
            <PlaceOperationTime
              requestData={taskData}
              date={date}
              dateDisplay={dateDisplay}
            />
            <OperationTimeByPerson
              requestData={taskDataPerson}
              date={date}
              dateDisplay={dateDisplay}
            />{" "}
          </>
        )}
      </Box>
    </Box>
  );
}

OperationKpi.propTypes = {
  robotFloorCoverage: PropTypes.bool.isRequired,
  taskReport: PropTypes.bool.isRequired,
  janitorCoverage: PropTypes.bool.isRequired,
  onDemandCleaningResponse: PropTypes.bool.isRequired,
  cleaningRequestResponse: PropTypes.bool.isRequired,
};
