import moment from "moment";
import React from "react";
import BasicLanguage from "../../../languages/Basic";
import { Box, Stack, Typography } from "@mui/material";
import { SbxStackedBarChartPlacePerson } from "../../SbxChart";
import { useRecoilValue } from "recoil";
import languageState from "../../../../recoil/atoms/languageState";
import PropTypes from "prop-types";
import facilityState from "../../../../recoil/atoms/facilityState";
import { getNameByUid } from "../../../utils/getUser";

export default function OperationTimeByPerson({
  requestData,
  date,
  dateDisplay,
}) {
  const language = useRecoilValue(languageState);
  const facility = useRecoilValue(facilityState);
  if (dateDisplay === "day") {
    requestData = generateDataByDay(requestData, language, date, facility);
  } else if (dateDisplay === "week") {
    requestData = generateDataByWeek(requestData, language, date, facility);
  } else if (dateDisplay === "month") {
    requestData = generateDataByMonth(requestData, language, date, facility);
  }

  return (
    <Box sx={{ width: "50%", height: "300px" }}>
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: "1.2em",
        }}
      >
        {BasicLanguage.dashboard.kpi.personInChargeWorkTime.title[language]}
      </Typography>
      <SbxStackedBarChartPlacePerson
        data={requestData}
        language={language}
        bottomDataKey={
          BasicLanguage.dashboard.kpi.request.OperationTime[language]
        }
        topDataKey={BasicLanguage.dashboard.kpi.request.Person[language]}
      />
    </Box>
  );
}
OperationTimeByPerson.propTypes = {
  requestData: PropTypes.array.isRequired,
  date: PropTypes.object.isRequired,
  dateDisplay: PropTypes.string.isRequired,
};

const generateDataByWeek = (tasks, language, date, facility) => {
  if (!(date instanceof Date)) {
    try {
      date = new Date(date);
    } catch (e) {
      console.error("Invalid date provided to generateDataByWeek:", date);
      throw new Error("Invalid date provided to generateDataByWeek");
    }
  }

  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const firstWeek = getWeekNumber(startOfMonth);
  const lastWeek = getWeekNumber(endOfMonth);

  const defaultWeeks = {};

  for (let i = firstWeek; i <= lastWeek; i++) {
    defaultWeeks[i] = {
      totalHours: 0,
      Person: {},
      createdAt: null,
    };
  }

  tasks.forEach((task) => {
    if (!task.createdAt) {
      console.error("Invalid createdAt:", task);
      return;
    }
    const taskDate = new Date(task.createdAt);
    if (isNaN(taskDate.getTime())) {
      console.error("Invalid task date:", task);
      return;
    }
    const taskWeek = getWeekNumber(taskDate);
    const taskPerson = task.beaconHolder || "Unknown";
    const taskTotalHours = parseFloat(task.totalHours || 0);

    if (!defaultWeeks[taskWeek]) {
      defaultWeeks[taskWeek] = {
        totalHours: 0,
        Person: {},
        createdAt: null,
      };
    }

    const weekData = defaultWeeks[taskWeek];
    weekData.totalHours += taskTotalHours;

    if (!weekData.Person[taskPerson]) {
      weekData.Person[taskPerson] = 0;
    }
    weekData.Person[taskPerson] += taskTotalHours;

    if (!weekData.createdAt || weekData.createdAt > taskDate) {
      weekData.createdAt = taskDate;
    }
  });

  // Filter weeks with `totalHours > 0` and transform to chart-friendly format
  return Object.keys(defaultWeeks)
    .filter((week) => defaultWeeks[week].totalHours > 0)
    .sort((a, b) => parseInt(a) - parseInt(b))
    .map((week) => {
      const weekData = defaultWeeks[week];
      return {
        name: `${week}`,
        totalHours: parseFloat(weekData.totalHours.toFixed(2)),
        Person: Object.entries(weekData.Person)
          .map(
            ([person, hours]) =>
              `${getNameByUid(
                facility,
                person === "0"
                  ? BasicLanguage.sensor.maintain[language]
                  : person
              )}: ${hours.toFixed(2)}h`
          )
          .join(", "),
        createdAt: weekData.createdAt || null,
      };
    });
};

const generateDataByMonth = (tasks, language, date, facility) => {
  // Ensure `date` is a valid `Date` object
  if (!(date instanceof Date)) {
    try {
      date = new Date(date);
    } catch (e) {
      console.error("Invalid date provided to generateDataByMonth:", date);
      throw new Error("Invalid date provided to generateDataByMonth");
    }
  }

  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  // Initialize the month data
  const monthData = {
    totalHours: 0,
    Person: {}, // Use an object to track hours per person
    createdAt: null,
  };

  tasks.forEach((task) => {
    if (!task.createdAt) {
      console.error("Invalid createdAt:", task);
      return;
    }
    const taskDate = new Date(task.createdAt);
    if (isNaN(taskDate.getTime())) {
      console.error("Invalid task date:", task);
      return;
    }
    const taskPerson = task.beaconHolder || "Unknown";
    const taskTotalHours = parseFloat(task.totalHours || 0);

    monthData.totalHours += taskTotalHours;

    if (!monthData.Person[taskPerson]) {
      monthData.Person[taskPerson] = 0;
    }
    monthData.Person[taskPerson] += taskTotalHours;

    if (!monthData.createdAt || monthData.createdAt > taskDate) {
      monthData.createdAt = taskDate;
    }
  });

  // Get the month name
  const monthName = moment(date).format(
    BasicLanguage.dashboard.kpi.request.month[language]
  );
  // Transform into chart-friendly format
  return [
    {
      name: monthName,
      totalHours: parseFloat(monthData.totalHours.toFixed(2)),
      Person: Object.entries(monthData.Person)
        .map(
          ([person, hours]) =>
            `${getNameByUid(
              facility,
              person === "0" ? BasicLanguage.sensor.maintain[language] : person
            )}: ${hours.toFixed(2)}h`
        )
        .join(", "), // Format hours next to each person
      createdAt: monthData.createdAt || null,
    },
  ];
};

// Utility function to calculate the week number
const getWeekNumber = (date) => {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - startOfYear) / (24 * 60 * 60 * 1000));
  return Math.ceil((days + startOfYear.getDay() + 1) / 7);
};

const generateDataByDay = (tasks, language, date, facility) => {
  // Ensure `date` is a valid `Date` object
  if (!(date instanceof Date)) {
    try {
      date = new Date(date);
    } catch (e) {
      console.error("Invalid date provided to generateDataByDay:", date);
      throw new Error("Invalid date provided to generateDataByDay");
    }
  }

  const daysInMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();

  // Initialize defaultDays with all days in the month
  const defaultDays = Array.from({ length: daysInMonth }, (_, i) => ({
    day: i + 1,
    totalHours: 0,
    Person: {},
    createdAt: null,
  }));

  tasks.forEach((task) => {
    if (!task.createdAt) {
      console.error("Invalid createdAt:", task);
      return;
    }
    const taskDate = new Date(task.createdAt);
    if (isNaN(taskDate.getTime())) {
      console.error("Invalid task date:", task);
      return;
    }

    const taskDay = taskDate.getDate(); // Extract day from `createdAt`
    const taskPerson = task.beaconHolder || "Unknown";
    const taskTotalHours = parseFloat(task.totalHours || 0);

    const dayEntry = defaultDays[taskDay - 1];

    // Add taskTotalHours to the day's totalHours
    dayEntry.totalHours += taskTotalHours;

    // Accumulate hours for each person
    if (!dayEntry.Person[taskPerson]) {
      dayEntry.Person[taskPerson] = 0;
    }
    dayEntry.Person[taskPerson] += taskTotalHours;

    // Set createdAt for the day if not already present
    if (!dayEntry.createdAt || dayEntry.createdAt > taskDate) {
      dayEntry.createdAt = taskDate;
    }
  });

  // Transform into chart-friendly format

  return defaultDays.map((dayEntry) => ({
    name: `${dayEntry.day}`,
    totalHours: parseFloat(dayEntry.totalHours.toFixed(2)), // Total hours for the day
    Person: Object.entries(dayEntry.Person)
      .map(
        ([person, hours]) =>
          `${getNameByUid(
            facility,
            person === "0" ? BasicLanguage.sensor.maintain[language] : person
          )}: ${hours.toFixed(2)}h`
      )
      .join(", "), // Format hours next to each person
    createdAt: dayEntry.createdAt || null,
  }));
};
