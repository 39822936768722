export const CalculateBeconSessionsUtils = (beaconSignals) => {
  const INTERVAL_TIME = 600; // 10 minutes in seconds
  const MIN_TIME_DIFF = 60; // 1 minute minimum session duration

  const sortedSignals = beaconSignals.sort(
    (a, b) => a.createdAt.seconds - b.createdAt.seconds
  );

  const sessions = [];
  let currentSession = null;

  const createNewSession = (signal) => ({
    beaconHolder: signal.beaconHolder || "",
    place: signal.value || "",
    deviceId: signal.deviceId,
    inTime: signal.createdAt,
    outTime: signal.createdAt,
    signals: [signal],
  });

  for (let i = 0; i < sortedSignals.length; i++) {
    const currentSignal = sortedSignals[i];

    if (!currentSession) {
      currentSession = createNewSession(currentSignal);
      continue;
    }

    const timeDiff =
      currentSignal.createdAt.seconds - currentSession.outTime.seconds;

    const isSameBeacon =
      currentSession.beaconHolder === (currentSignal.beaconHolder || "") &&
      currentSession.place === (currentSignal.value || "");

    // Continue session if conditions are met
    if (isSameBeacon && timeDiff <= INTERVAL_TIME) {
      currentSession.outTime = currentSignal.createdAt;
      currentSession.signals.push(currentSignal);
    } else {
      // Process current session
      const sessionDuration =
        currentSession.outTime.seconds - currentSession.inTime.seconds;

      if (sessionDuration >= MIN_TIME_DIFF) {
        sessions.push(currentSession);
      }

      // Start a new session
      currentSession = createNewSession(currentSignal);
    }

    // Handle the last signal
    if (i === sortedSignals.length - 1) {
      const sessionDuration =
        currentSession.outTime.seconds - currentSession.inTime.seconds;

      if (sessionDuration >= MIN_TIME_DIFF) {
        sessions.push(currentSession);
      }
    }
  }

  return sessions.map((session) => ({
    beaconHolder: session.beaconHolder,
    place: session.place,
    deviceId: session.deviceId,
    inTime: session.inTime,
    outTime: session.outTime,
  }));
};

export const calculateHoursWorkedByPerson = (tasks) => {
  const INTERVAL_TIME = 600; // 10 minutes in seconds
  const MIN_TIME_DIFF = 60; // 1 minute in seconds

  // Ensure all tasks have a valid `beaconHolder`
  const sanitizedTasks = tasks.map((task) => ({
    ...task,
    beaconHolder: task.beaconHolder || "", // Default to an empty string if `beaconHolder` is undefined
  }));

  // Sort tasks by `createdAt`
  const sortedTasks = sanitizedTasks.sort(
    (a, b) => a.createdAt.getTime() - b.createdAt.getTime()
  );

  const sessions = [];
  let currentSession = null;

  for (let i = 0; i < sortedTasks.length; i++) {
    const currentTask = sortedTasks[i];

    if (!currentSession) {
      currentSession = {
        beaconHolder: currentTask.beaconHolder,
        inTime: currentTask.createdAt,
        outTime: currentTask.createdAt,
        place: currentTask.place, // Track the place in the session
        signals: [currentTask],
      };
      continue;
    }

    const samePerson = currentSession.beaconHolder === currentTask.beaconHolder;
    const samePlace = currentSession.place === currentTask.place;
    const sameDay =
      currentSession.inTime?.toDateString() ===
      currentTask.createdAt?.toDateString();
    const timeDiff =
      (currentTask.createdAt.getTime() - currentSession.outTime.getTime()) /
      1000; // Convert ms to seconds

    if (samePerson && samePlace && sameDay && timeDiff <= INTERVAL_TIME) {
      currentSession.outTime = currentTask.createdAt;
      currentSession.signals.push(currentTask);
    } else {
      // Custom session duration calculation in minutes
      const sessionDurationMinutes =
        (currentSession.outTime.getTime() - currentSession.inTime.getTime()) /
        60000; // Convert ms to minutes

      // Add session if it is valid
      if (sessionDurationMinutes >= MIN_TIME_DIFF / 60) {
        sessions.push(currentSession);
      }

      // Start a new session
      currentSession = {
        beaconHolder: currentTask.beaconHolder,
        inTime: currentTask.createdAt,
        outTime: currentTask.createdAt,
        place: currentTask.place, // Start a new session with the new place
        signals: [currentTask],
      };
    }
  }

  // Add the last session if valid
  if (
    currentSession &&
    (currentSession.outTime.getTime() - currentSession.inTime.getTime()) /
      1000 >=
      MIN_TIME_DIFF
  ) {
    sessions.push(currentSession);
  }

  // Assign the correct `createdAt` for each session
  return sessions.map((session) => {
    const sessionDurationMinutes =
      (session.outTime.getTime() - session.inTime.getTime()) / 60000; // Convert ms to minutes
    const customRepresentation = sessionDurationMinutes / 60;

    return {
      beaconHolder: session.beaconHolder,
      createdAt: session.inTime, // Assign `inTime` as the session's initial timestamp
      totalHours: parseFloat(customRepresentation.toFixed(2)), // Round to 2 decimals
    };
  });
};

export const calculateHoursWorkedByPlace = (tasks) => {
  const INTERVAL_TIME = 600; // 10 minutes in seconds
  const MIN_TIME_DIFF = 60; // 1 minute in seconds

  // Sort tasks by `createdAt`
  const sortedTasks = tasks.sort(
    (a, b) => a.createdAt.getTime() - b.createdAt.getTime()
  );

  const sessions = [];
  let currentSession = null;

  for (let i = 0; i < sortedTasks.length; i++) {
    const currentTask = sortedTasks[i];

    // Start a new session if none exists
    if (!currentSession) {
      currentSession = {
        place: currentTask.place,
        inTime: currentTask.createdAt,
        outTime: null, // Initially no outTime
        signals: [currentTask],
      };
      continue;
    }

    const samePlace = currentSession.place === currentTask.place;
    const timeDiff =
      (currentTask.createdAt.getTime() -
        (currentSession.outTime || currentSession.inTime).getTime()) /
      1000; // Convert ms to seconds

    // Update current session if conditions are met
    if (samePlace && timeDiff <= INTERVAL_TIME) {
      currentSession.outTime = currentTask.createdAt;
      currentSession.signals.push(currentTask);
    } else {
      // Finalize current session if it meets the minimum time requirement
      if (
        currentSession.outTime &&
        (currentSession.outTime.getTime() - currentSession.inTime.getTime()) /
          1000 >=
          MIN_TIME_DIFF
      ) {
        sessions.push(currentSession);
      }

      // Start a new session
      currentSession = {
        place: currentTask.place,
        inTime: currentTask.createdAt,
        outTime: null, // Initially no outTime
        signals: [currentTask],
      };
    }
  }

  // Check the last session
  if (
    currentSession &&
    currentSession.outTime &&
    (currentSession.outTime.getTime() - currentSession.inTime.getTime()) /
      1000 >=
      MIN_TIME_DIFF
  ) {
    sessions.push(currentSession);
  }

  // Format sessions with `createdAt` from `inTime`
  return sessions.map((session) => {
    const sessionDurationMinutes =
      (session.outTime.getTime() - session.inTime.getTime()) / 60000; // Convert ms to minutes
    const customRepresentation = sessionDurationMinutes / 60;

    return {
      place: session.place,
      createdAt: session.inTime, // Use `inTime` as the session's initial timestamp
      totalHours: parseFloat(customRepresentation.toFixed(2)), // Round to 2 decimals
    };
  });
};
