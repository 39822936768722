import * as React from "react";
import { IconButton } from "@mui/material";

import moment from "moment";

import {
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../utils/firebase";
import { useEffect } from "react";

import ReplayIcon from "@mui/icons-material/Replay";
import BasicLanguage from "../../components/languages/Basic";
import { TimeZoneContext } from "../utils/setTimeZoneContext";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const truncation = (value) => {
  return Math.floor(value * Math.pow(10, 2)) / Math.pow(10, 2);
};

const SensorCurrentValue = (props) => {
  const facilityId = props.facilityId;
  const sensorId = props.sensorId;
  const sensorProcess = props.sensorProcess;
  const reloadButton = props.reloadButton;
  const language = props.language || "ja";
  const { currentTimeZoneName } = React.useContext(TimeZoneContext);
  const [currentValue, setCurrentValue] = React.useState(null);
  const [lastUpdated, setLastUpdated] = React.useState(null);
  const [lastReset, setLastReset] = React.useState(null);
  const [dataType, setDataType] = React.useState(null);

  const getCurrentValue = async (facilityId, sensorId, calcType) => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId, "sensors", sensorId))
      .then(async (x) => {
        const data = x.data();
        const value = truncation(data.currentValue);
        setDataType(data.type)

        let displayValue;

        if (data.process === 0) {
          const q = query(
            collection(
              db,
              ENV,
              VERSION,
              "facilities",
              facilityId,
              "sensorHistory"
            ),
            and(
              where("deviceId", "==", sensorId),
              where("dataType", "==", "data")
            ),
            orderBy("createdAt", "desc"),
            limit(1)
          );
          const querysnapshot = await getDocs(q).catch((e) => console.error(e));
          try {
            setCurrentValue(querysnapshot?.docs[0]?.data().value);
            setLastUpdated(
              data.lastUpdate
                ? moment(data.lastUpdate.seconds * 1000)
                    .tz(currentTimeZoneName)
                    .format("YYYY/MM/DD HH:mm:ss")
                : ""
            );
          } catch (e) {
            setCurrentValue("");
          }
        } else {
          if (value) {
            displayValue = value;
          } else if (data.lastUpdate) {
            displayValue = 0;
          } else {
            displayValue = 0;
          }
          setCurrentValue(displayValue);
          setLastUpdated(
            data.lastUpdate
              ? moment(data.lastUpdate.seconds * 1000)
                  .tz(currentTimeZoneName)
                  .format("YYYY/MM/DD HH:mm:ss")
              : ""
          );
          setLastReset(
            data.lastReset
              ? moment(data.lastReset.seconds * 1000)
                  .tz(currentTimeZoneName)
                  .format("YYYY/MM/DD HH:mm:ss")
              : ""
          );
        }
      })
      .catch((e) => console.error(e));
  };

  const reload = () => {
    if (
      facilityId !== undefined &&
      sensorId !== undefined &&
      sensorProcess !== undefined
    ) {
      getCurrentValue(facilityId, sensorId, sensorProcess);
    } else {
      getDocs(
        query(collection(db, ENV, VERSION, "facilities", facilityId, "qr"))
      );
    }
  };

  useEffect(() => {
    if (
      facilityId !== undefined &&
      sensorId !== undefined &&
      sensorProcess !== undefined
    ) {
      getCurrentValue(facilityId, sensorId, sensorProcess);
    } else {
    }
  }, [facilityId, sensorId, sensorProcess]);

  return (
    <>
      {dataType === "webiot_ibeacon" ? (
          <>
            {currentValue}
            {lastUpdated ? `(${lastUpdated})` : ""}
          </>
        ) : (
          <>
            {currentValue}
            {lastUpdated ? `(${lastUpdated})` : ""}
            {reloadButton ? (
              <IconButton onClick={reload}>
                <ReplayIcon />
              </IconButton>
            ) : (
              <></>
            )}
            <br />
            {lastReset && sensorProcess !== 0
              ? `${BasicLanguage.sensor.reset[language]}: ${lastReset}`
              : ""}
          </>
        )}
    </>
  );
};
export default SensorCurrentValue;
