import { useEffect, useState } from "react";
import BasicLanguage from "../../languages/Basic";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import "moment/locale/ja";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CreateTask from "../CreateTask";
import { useNavigate } from "react-router";

const blank = (value) => {
  return !value || value.length === 0;
};

const momentIsBefore = (moment1, moment2) => {
  return moment1.isBefore(moment2);
};

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const AddTaskDatePicker = (props) => {
  const language = props.language;
  const date = props.date;
  const name = props.name;
  const complete = props.complete;
  const minDate = complete ? null : props.minDate;
  const maxDate = complete ? moment() : moment().add(1, "year").endOf("month");
  return (
    <FormControl error={date.hasError}>
      <FormLabel>{props.label}</FormLabel>
      <DatePicker
        name={name}
        minDate={minDate}
        maxDate={maxDate}
        value={date.value}
        onChange={(value) => props.handleDateTimeChange(value, name)}
        format={BasicLanguage.common.date.datePicker.long[language]}
        slotProps={{
          actionBar: {
            actions: ["cancel", "accept"],
          },
          textField: {
            error: date.hasError,
          },
        }}
        closeOnSelect={false}
      />
      {date.hasError && (
        <FormHelperText>{date.errorMessage[language]}</FormHelperText>
      )}
    </FormControl>
  );
};

const ReportsAddForm = (props) => {
  const navigate = useNavigate();
  const language = props.language;
  const facilityId = props.facilityId;
  const taskIsComplete = props.complete || false;
  const REPEAT = {
    0: BasicLanguage.reportsAdd.repeat[0][language],
    1: BasicLanguage.reportsAdd.repeat[1][language],
    2: BasicLanguage.reportsAdd.repeat[2][language],
  };

  const DAY = {
    1: BasicLanguage.reportsAdd.weekDay[1][language],
    2: BasicLanguage.reportsAdd.weekDay[2][language],
    3: BasicLanguage.reportsAdd.weekDay[3][language],
    4: BasicLanguage.reportsAdd.weekDay[4][language],
    5: BasicLanguage.reportsAdd.weekDay[5][language],
    6: BasicLanguage.reportsAdd.weekDay[6][language],
    7: BasicLanguage.reportsAdd.weekDay[7][language],
  };

  const [users, setUsers] = useState({});
  const [requests, setRequests] = useState([]);
  const [fetch, setFetch] = useState();
  const [addTaskForm, setAddTaskForm] = useState({
    request: {
      value: "",
      hasError: false,
      validation: {
        notBlank: {
          hasError() {
            return blank(addTaskForm.request.value);
          },
          message: BasicLanguage.reportsAdd.error.request,
        },
      },
    },
    location: {
      value: "",
      hasError: false,
      validation: {
        notBlank: {
          hasError() {
            return blank(addTaskForm.location.value);
          },
          message: BasicLanguage.reportsAdd.error.location,
        },
      },
    },
    taskMemo: {
      value: "",
      hasError: false,
      validation: {},
    },
    reportOutline: {
      value: "",
      hasError: false,
      validation: {},
    },
    date: {
      value: null,
      hasError: false,
      validation: {
        notBlank: {
          hasError() {
            if (addTaskForm.repeat.value === "0") {
              return blank(addTaskForm.date.value);
            }
          },
          message: BasicLanguage.reportsAdd.error.date,
        },
      },
    },
    repeat: {
      value: "0",
      hasError: false,
      validation: {
        notBlank: {
          hasError() {
            return blank(addTaskForm.repeat.value);
          },
          message: BasicLanguage.reportsAdd.error.repeat,
        },
      },
    },
    timeIsNotSame: {
      value: "",
      hasError: false,
      validation: {
        hasError() {
          if (addTaskForm.time.value) {
            return blank(addTaskForm.endTime.value).isSame(
              addTaskForm.time.value
            );
          }
          return false;
        },
        message: BasicLanguage.reportsAdd.error.timeSame[language],
      },
    },
    endTime: {
      value: null,
      hasError: false,
      validation: {
        notBlank: {
          hasError() {
            if (addTaskForm.endTime?.value && addTaskForm.time?.value) {
              return moment(addTaskForm.endTime.value).isBefore(
                addTaskForm.time.value
              );
            }
            return false;
          },
          message: BasicLanguage.reportsAdd.error.endTImeBefore,
        },
      },
    },
    time: {
      value: null,
      hasError: false,
      validation: {
        notBlank: {
          hasError() {
            return blank(addTaskForm.time.value);
          },
          message: BasicLanguage.reportsAdd.error.time,
        },
      },
    },
    ...(taskIsComplete && {
      cleaner: {
        value: "",
        hasError: false,
        validation: {
          notBlank: {
            hasError() {
              return blank(addTaskForm.cleaner.value);
            },
            message: BasicLanguage.reportsAdd.error.assignee,
          },
        },
      },
    }),
    ...(!taskIsComplete && {
      assignee: {
        value: [],
        hasError: false,
        validation: {
          notBlank: {
            hasError() {
              return blank(addTaskForm.assignee.value);
            },
            message: BasicLanguage.reportsAdd.error.assignee,
          },
        },
      },
      startDate: {
        value: null,
        hasError: false,
        validation: {
          notBlank: {
            hasError() {
              if (addTaskForm.repeat.value !== "0") {
                return blank(addTaskForm.startDate.value);
              }
            },
            message: BasicLanguage.reportsAdd.error.startDate,
          },
        },
      },
      endDate: {
        value: null,
        hasError: false,
        validation: {
          notBlank: {
            hasError() {
              if (addTaskForm.repeat.value !== "0") {
                return blank(addTaskForm.endDate.value);
              }
            },
            message: BasicLanguage.reportsAdd.error.endDate,
          },
          dateIsAfter: {
            hasError() {
              if (
                addTaskForm.repeat.value !== "0" &&
                addTaskForm.startDate.value
              ) {
                return momentIsBefore(
                  addTaskForm.endDate.value,
                  addTaskForm.startDate.value
                );
              }
            },
            message: BasicLanguage.reportsAdd.error.endDateBefore,
          },
        },
      },
      days: {
        value: [],
        hasError: false,
        validation: {
          notBlank: {
            hasError() {
              if (addTaskForm.repeat.value === "2") {
                return blank(addTaskForm.days.value);
              }
            },
            message: BasicLanguage.reportsAdd.error.days,
          },
        },
      },
    }),
  });

  const getUsers = () => {
    // 施設ユーザー全員を取得
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId))
      .then((facilitySnapshot) => {
        const usersArray = [
          ...Object.values(facilitySnapshot.data().admin),
          ...Object.values(facilitySnapshot.data().users),
        ].filter((user) => !user.isDeleted);
        const userObj = {};
        usersArray.forEach((user) => {
          userObj[user.uid] = user.displayName;
        });
        setUsers(userObj);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const getRequests = () => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "taskRequest")
      )
    )
      .then((querysnapshot) =>
        setRequests(
          querysnapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          })
        )
      )
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    getUsers();
    getRequests();
  }, [fetch]);

  useEffect(() => {
    moment.locale(language);
  }, [language]);

  const handleAssigneeChange = (event) => {
    const {
      target: { value },
    } = event;
    setAddTaskForm((prevAddTaskForm) => {
      prevAddTaskForm.assignee.value =
        typeof value === "string" ? value.split(",") : value;
      return { ...prevAddTaskForm };
    });
  };

  const handleCheckboxChange = (event) => {
    setAddTaskForm((prevAddTaskForm) => {
      if (event.target.checked) {
        prevAddTaskForm.days.value = [
          ...prevAddTaskForm.days.value,
          event.target.value,
        ];
      } else {
        prevAddTaskForm.days.value = prevAddTaskForm.days.value.filter(
          (day) => day !== event.target.value
        );
      }
      return { ...prevAddTaskForm };
    });
  };

  const handleRepeatChange = (event) => {
    setAddTaskForm((prevAddTaskForm) => {
      if (event.target.value === "0") {
        //繰り返さない場合
        prevAddTaskForm.startDate.value = null;
        prevAddTaskForm.startDate.hasError = null;
        prevAddTaskForm.endDate.value = null;
        prevAddTaskForm.endDate.hasError = false;
      } else {
        //繰り返す場合
        prevAddTaskForm.date.value = null;
        prevAddTaskForm.date.hasError = false;
      }
      if (event.target.value !== "2") {
        //週の繰り返しではない場合
        prevAddTaskForm.days.value = [];
        prevAddTaskForm.days.hasError = false;
      }
      prevAddTaskForm.repeat.value = event.target.value;
      return { ...prevAddTaskForm };
    });
  };

  const handleDateTimeChange = (value, name) => {
    setAddTaskForm((prevAddTaskForm) => {
      prevAddTaskForm[name].value = value;
      return { ...prevAddTaskForm };
    });
  };

  const handleChange = (event) => {
    setAddTaskForm((prevAddTaskForm) => {
      prevAddTaskForm[event.target.name].value = event.target.value;
      return { ...prevAddTaskForm };
    });
  };

  const checkForError = (object, item) => {
    const validation = object[item].validation;
    if (validation.notBlank && validation.notBlank.hasError()) {
      return validation.notBlank.message;
    }
    if (validation.dateIsAfter && validation.dateIsAfter.hasError()) {
      return validation.dateIsAfter.message;
    }
    if (validation.timeIsAfter && validation.timeIsAfter.hasError()) {
      return validation.timeIsAfter.message;
    }
  };

  const submitHandler = (event) => {
    let addTaskFormCopy = { ...addTaskForm };
    let formIsValid = true;

    Object.keys(addTaskFormCopy).forEach((item) => {
      let errorMessage = checkForError(addTaskFormCopy, item);
      if (errorMessage) {
        addTaskFormCopy[item].errorMessage = errorMessage;
        addTaskFormCopy[item].hasError = true;
        formIsValid = false;
      } else {
        addTaskFormCopy[item].hasError = false;
      }
    });
    setAddTaskForm({ ...addTaskFormCopy });
    if (formIsValid) {
      addFirebaseTask().then((hasError) => {
        if (hasError) {
          alert(BasicLanguage.alert.error.default[language]);
        } else {
          alert(BasicLanguage.alert.added[language]);
          navigate("../reports");
        }
      });
    }
  };

  const addFirebaseTask = async () => {
    let firebaseError = false;
    const startDate = addTaskForm.date.value || addTaskForm.startDate.value;
    const startDateTime = startDate.clone().set({
      hour: addTaskForm.time.value.get("hour"),
      minute: addTaskForm.time.value.get("minute"),
      second: 0,
      millisecond: 0,
    });
    const endDateTime = startDateTime.clone();
    if (addTaskForm.endDate && addTaskForm.endDate.value) {
      endDateTime.set({
        years: addTaskForm.endDate.value.get("year"),
        month: addTaskForm.endDate.value.get("month"),
        date: addTaskForm.endDate.value.get("date"),
      });
    }
    while (startDateTime.isSameOrBefore(endDateTime)) {
      if (
        addTaskForm.repeat.value !== "2" ||
        addTaskForm.days.value.includes(startDateTime.isoWeekday().toString())
      ) {
        try {
          await writeFirebaseNotify({
            cleaner: taskIsComplete ? addTaskForm.cleaner.value : "",
            assignee: taskIsComplete
              ? [addTaskForm.cleaner.value]
              : addTaskForm.assignee.value,
            location: addTaskForm.location.value,
            request: addTaskForm.request.value,
            repeat: addTaskForm.repeat.value,
            taskMemo: addTaskForm.taskMemo.value,
            createdAt: startDateTime.toDate(),
            startTime: addTaskForm.time.value,
            endTime: addTaskForm.endTime.value,
            reportOutline: addTaskForm.reportOutline.value,
          });
        } catch (e) {
          console.error(e);
          firebaseError = true;
        }
      }
      startDateTime.add(1, "days");
    }
    return firebaseError;
  };

  const writeFirebaseNotify = async (data) => {
    try {
      await addDoc(
        collection(db, ENV, VERSION, "facilities", facilityId, "notify"),
        {
          createdAt: Timestamp.fromDate(data.createdAt),
          imagePath: "",
          task: {
            assignee: data.assignee,
            completed: taskIsComplete,
            completedBy: data.cleaner,
            place: data.location,
            request: data.request,
            taskMemo: data.taskMemo,
            endTime: data.endTime
              ? moment(data.createdAt)
                  .set({
                    hour: data.endTime.get("hour"),
                    minute: data.endTime.get("minute"),
                    second: 0,
                    millisecond: 0,
                  })
                  .toDate()
              : null,
          },
          type: "TASK",
          repeat: parseInt(data.repeat),
          report: {
            outline: data.reportOutline,
          },
        }
      );
    } catch (e) {
      console.error(e);
      throw new Error("Firebase Error", e);
    }
  };

  return (
    <Stack justifyContent="center" spacing={2}>
      <Box sx={{ width: "100%" }}>
        {/* *****cleaner 開始 ***** */}
        {taskIsComplete && (
          <FormControl
            sx={{ m: 1, width: "100%" }}
            error={addTaskForm.cleaner.hasError}
          >
            <FormLabel>{BasicLanguage.reportsAdd.assignee[language]}</FormLabel>
            <Select
              name="cleaner"
              value={addTaskForm.cleaner.value}
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 200 },
                },
              }}
            >
              {Object.keys(users).map((userId) => {
                return (
                  <MenuItem key={userId} value={userId}>
                    {users[userId]}
                  </MenuItem>
                );
              })}
            </Select>
            {addTaskForm.cleaner.hasError && (
              <FormHelperText>
                {addTaskForm.cleaner.errorMessage[language]}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {/* *****cleaner 終了 ***** */}
        {/* *****assignee 開始 ***** */}
        {!taskIsComplete && (
          <FormControl
            sx={{ m: 1, width: "100%" }}
            error={addTaskForm.assignee.hasError}
          >
            <FormLabel>{BasicLanguage.reportsAdd.assignee[language]}</FormLabel>
            <Select
              name="assignee"
              value={addTaskForm.assignee.value}
              onChange={handleAssigneeChange}
              multiple
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 200 },
                },
              }}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={users[value]} />
                  ))}
                </Box>
              )}
            >
              {Object.keys(users).map((userId) => {
                return (
                  <MenuItem key={userId} value={userId}>
                    {users[userId]}
                  </MenuItem>
                );
              })}
            </Select>
            {addTaskForm.assignee.hasError && (
              <FormHelperText>
                {addTaskForm.assignee.errorMessage[language]}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {/* *****assignee 終了 ***** */}
        {/* *****request 開始 ***** */}
        <FormControl
          sx={{ m: 1, width: "100%" }}
          error={addTaskForm.request.hasError}
        >
          <FormLabel>{BasicLanguage.reportsAdd.request[language]}</FormLabel>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Select
              name="request"
              value={addTaskForm.request.value}
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 200 },
                },
              }}
              sx={{ width: "90%" }}
            >
              {requests.map((request) => {
                return (
                  <MenuItem key={request.id} value={request.name}>
                    {request.name}
                  </MenuItem>
                );
              })}
            </Select>
            <CreateTask
              existingTasks={requests}
              taskType="taskRequest"
              fetch={setFetch}
            />
          </Box>
          {addTaskForm.request.hasError && (
            <FormHelperText>
              {addTaskForm.request.errorMessage[language]}
            </FormHelperText>
          )}
        </FormControl>
        {/* *****request 終了 ***** */}
        {/* *****location 開始 ***** */}
        <FormControl
          sx={{ m: 1, width: "100%" }}
          error={addTaskForm.location.hasError}
        >
          <FormLabel>{BasicLanguage.reportsAdd.location[language]}</FormLabel>
          <TextField
            variant="outlined"
            name="location"
            sx={{ m: 0 }}
            value={addTaskForm.location.value}
            onChange={handleChange}
            error={addTaskForm.location.hasError}
          />
          {addTaskForm.location.hasError && (
            <FormHelperText>
              {addTaskForm.location.errorMessage[language]}
            </FormHelperText>
          )}
        </FormControl>
        {/* *****location 終了 ***** */}
        {/* *****taskMemo 開始 ***** */}
        <FormControl
          sx={{ m: 1, width: "100%" }}
          error={addTaskForm.taskMemo.hasError}
        >
          <FormLabel>{BasicLanguage.reportsAdd.memo[language]}</FormLabel>
          <TextField
            multiline
            variant="outlined"
            name="taskMemo"
            sx={{ m: 0 }}
            value={addTaskForm.taskMemo.value}
            onChange={handleChange}
            error={addTaskForm.taskMemo.hasError}
          />
          {addTaskForm.taskMemo.hasError && (
            <FormHelperText>
              {addTaskForm.taskMemo.errorMessage[language]}
            </FormHelperText>
          )}
        </FormControl>
        {/* *****taskMemo 終了 ***** */}
        {/* *****reportOutline 開始 ***** */}
        {taskIsComplete && (
          <FormControl
            sx={{ m: 1, width: "100%" }}
            error={addTaskForm.reportOutline.hasError}
          >
            <FormLabel>
              {BasicLanguage.reportsAdd.complete.reportOutline[language]}
            </FormLabel>
            <TextField
              multiline
              variant="outlined"
              name="reportOutline"
              sx={{ m: 0 }}
              value={addTaskForm.reportOutline.value}
              onChange={handleChange}
              error={addTaskForm.reportOutline.hasError}
            />
            {addTaskForm.reportOutline.hasError && (
              <FormHelperText>
                {addTaskForm.reportOutline.errorMessage[language]}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {/* *****reportOutline 終了 ***** */}
        {/* *****repeat 開始 ***** */}
        {!taskIsComplete && (
          <FormControl
            sx={{ m: 1, width: "100%" }}
            error={addTaskForm.repeat.hasError}
          >
            <FormLabel>
              {BasicLanguage.reportsAdd.repeat.title[language]}
            </FormLabel>
            <Select
              name="repeat"
              value={addTaskForm.repeat.value}
              onChange={handleRepeatChange}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 200 },
                },
              }}
              disabled={taskIsComplete}
            >
              {Object.keys(REPEAT).map((repeatId) => {
                return (
                  <MenuItem key={repeatId} value={repeatId}>
                    {REPEAT[repeatId]}
                  </MenuItem>
                );
              })}
            </Select>
            {addTaskForm.repeat.hasError && (
              <FormHelperText>
                {addTaskForm.repeat.errorMessage[language]}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {/* *****repeat 終了 ***** */}
        {/* *****days 開始 ***** */}
        {addTaskForm.repeat.value === "2" && (
          <FormControl
            sx={{ mt: 1, width: "100%" }}
            error={addTaskForm.days.hasError}
          >
            <FormLabel>
              {BasicLanguage.reportsAdd.repeat.weekTitle[language]}
            </FormLabel>
            <FormGroup
              row
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {Object.keys(DAY).map((dayKey) => {
                return (
                  <FormControlLabel
                    key={dayKey}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: "1.5em" },
                    }}
                    control={
                      <Checkbox
                        value={dayKey}
                        checked={addTaskForm.days.value.includes(dayKey)}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={DAY[dayKey]}
                    labelPlacement="top"
                  />
                );
              })}
            </FormGroup>
            {addTaskForm.days.hasError && (
              <FormHelperText>
                {addTaskForm.days.errorMessage[language]}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {/* *****days 終了 ***** */}
        <FormControl sx={{ m: 1, width: "100%" }}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={language}
            localeText={{
              cancelButtonLabel:
                BasicLanguage.common.date.datePicker.cancel[language],
            }}
          >
            <Box sx={{ display: "flex", gap: 3 }}>
              {/* *****date 開始 ***** */}
              {addTaskForm.repeat.value === "0" ? (
                <AddTaskDatePicker
                  date={addTaskForm.date}
                  name="date"
                  minDate={moment().startOf("month")}
                  language={language}
                  handleDateTimeChange={handleDateTimeChange}
                  label={BasicLanguage.reportsAdd.date[language]}
                  complete={taskIsComplete}
                />
              ) : (
                /* *****date 終了 ***** */
                /* *****startDate 開始 ***** */
                <>
                  <AddTaskDatePicker
                    date={addTaskForm.startDate}
                    name="startDate"
                    minDate={moment().startOf("month")}
                    language={language}
                    handleDateTimeChange={handleDateTimeChange}
                    label={BasicLanguage.reportsAdd.startDate[language]}
                  />
                  {/* *****StartDate 終了 ***** */}
                  {/* *****endDate 開始 ***** */}
                  {addTaskForm.repeat.value !== "0" && (
                    <AddTaskDatePicker
                      date={addTaskForm.endDate}
                      name="endDate"
                      minDate={
                        addTaskForm.startDate.value || moment().startOf("month")
                      }
                      language={language}
                      repeat={addTaskForm.repeat.value}
                      handleDateTimeChange={handleDateTimeChange}
                      label={BasicLanguage.reportsAdd.endDate[language]}
                    />
                  )}
                  {/* *****endDate 終了 ***** */}
                </>
              )}
              {/* *****time 開始 ***** */}
              <FormControl error={addTaskForm.time.hasError}>
                <FormLabel>{BasicLanguage.reportsAdd.time[language]}</FormLabel>
                <TimePicker
                  value={addTaskForm.time.value}
                  onChange={(value) => handleDateTimeChange(value, "time")}
                  views={["hours", "minutes"]}
                  ampm={BasicLanguage.reportsAdd.ampm[language]}
                  slotProps={{
                    textField: {
                      error: addTaskForm.time.hasError,
                    },
                  }}
                />
                {addTaskForm.time.hasError && (
                  <FormHelperText>
                    {addTaskForm.time.errorMessage[language]}
                  </FormHelperText>
                )}
              </FormControl>
              {/* *****終了time***** */}
              <FormControl error={addTaskForm.endTime.hasError}>
                <FormLabel>
                  {BasicLanguage.reportsAdd.timeEnd[language]}
                </FormLabel>
                <TimePicker
                  value={addTaskForm.endTime.value}
                  onChange={(value) => handleDateTimeChange(value, "endTime")}
                  views={["hours", "minutes"]}
                  ampm={BasicLanguage.reportsAdd.ampm[language]}
                  slotProps={{
                    textField: {
                      error: addTaskForm.time.hasError,
                    },
                  }}
                />
                {addTaskForm.endTime.hasError && (
                  <FormHelperText>
                    {addTaskForm.endTime.errorMessage[language]}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </LocalizationProvider>
        </FormControl>
        <Button
          onClick={submitHandler}
          fullWidth
          variant="contained"
          sx={{ ml: "1%", mt: "2%" }}
        >
          {props.buttonTitle}
        </Button>
      </Box>
    </Stack>
  );
};

export default ReportsAddForm;
