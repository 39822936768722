import * as React from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  Stack,
  Button,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import BasicLanguage from "../components/languages/Basic";
import SBXStyles from "../components/utils/Styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import "moment-timezone";
import "moment/locale/ja";
import languageState from "../recoil/atoms/languageState";
import { TimeZoneContext } from "../components/utils/setTimeZoneContext";
import Typography from "../components/parts-ui/Typography";
import { db } from "../components/utils/firebase";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useRecoilValue } from "recoil";
import SensorCurrentValue from "../components/parts/SensorCurrentValue";
import BatteryStatus from "../components/parts/BatteryStatus";
import Loading from "../components/parts/Loading";
import DateRangePicker from "../components/parts/shared/DateRangePicker";
import { getNameByUid } from "../components/utils/getUser";
import facilityState from "../recoil/atoms/facilityState";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const ITEMS_PER_PAGE = 20;

export default function Sensors() {
  const params = useParams();
  const facilityId = params.facilityId;
  const [selectAllSensors, setSelectAllSensors] = React.useState(false);
  const language = useRecoilValue(languageState);
  const [sensors, setSensors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedSensors, setSelectedSensors] = React.useState({});
  const [startDateRange, setStartDateRange] = React.useState(null);
  const [endDateRange, setEndDateRange] = React.useState(null);
  const [downloading, setDownloading] = React.useState(null);
  const { currentTimeZoneName } = React.useContext(TimeZoneContext);
  const [startDateError, setStartDateError] = React.useState(null);
  const [endDateError, setEndDateError] = React.useState(null);
  const facility = useRecoilValue(facilityState);
  React.useEffect(() => {
    setLoading(true);
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "sensors"),
        where("active", "==", true)
      )
    )
      .then((querysnapshot) => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      });
  }, [facilityId]);

  const toggleSensorSelection = (sensorId) => {
    setSelectedSensors((prevState) => ({
      ...prevState,
      [sensorId]: !prevState[sensorId],
    }));

    console.log(sensors);
  };

  const toggleSelectAllSensors = () => {
    setSelectAllSensors(!selectAllSensors);
    const allSensorIds = sensors.map((sensor) => sensor.id);
    const updatedSelectedSensors = {};
    allSensorIds.forEach((sensorId) => {
      updatedSelectedSensors[sensorId] = !selectAllSensors;
    });
    setSelectedSensors(updatedSelectedSensors);
  };

  const calculateBeaconSessions = (beaconSignals) => {
    const INTERVAL_TIME = 600; // 10 minutes in seconds
    const MIN_TIME_DIFF = 60; // 1 minute minimum session duration

    const sortedSignals = beaconSignals.sort(
      (a, b) => a.createdAt.seconds - b.createdAt.seconds
    );

    console.log(sortedSignals, "sorted signals");
    const sessions = [];
    let currentSession = null;

    const createNewSession = (signal) => ({
      place: signal.value,
      beaconHolder: signal.beaconHolder,
      deviceId: signal.deviceId,
      value: signal.value,
      inTime: signal.createdAt,
      outTime: signal.createdAt,
      signals: [signal],
    });

    for (let i = 0; i < sortedSignals.length; i++) {
      const currentSignal = sortedSignals[i];

      if (!currentSession) {
        currentSession = createNewSession(currentSignal);
        continue;
      }

      const timeDiff =
        currentSignal.createdAt.seconds - currentSession.outTime.seconds;

      // Continue the session if within the 10-minute interval
      if (
        currentSession.deviceId === currentSignal.deviceId &&
        currentSession.value === currentSignal.value &&
        timeDiff <= INTERVAL_TIME
      ) {
        currentSession.outTime = currentSignal.createdAt;
        currentSession.signals.push(currentSignal);
      } else {
        // Finalize the current session if it meets the minimum duration
        const sessionDuration =
          currentSession.outTime.seconds - currentSession.inTime.seconds;

        if (sessionDuration >= MIN_TIME_DIFF) {
          sessions.push(currentSession);
        }

        // Start a new session
        currentSession = createNewSession(currentSignal);
      }

      // Process the last session
      if (i === sortedSignals.length - 1) {
        const sessionDuration =
          currentSession.outTime.seconds - currentSession.inTime.seconds;

        if (sessionDuration >= MIN_TIME_DIFF) {
          sessions.push(currentSession);
        }
      }
    }

    return sessions.map((session) => ({
      deviceId: session.deviceId,
      place: session.place,
      inTime: session.inTime,
      outTime: session.outTime,
      beaconHolder: session.beaconHolder,
    }));
  };

  const downloadDataByDateRange = async () => {
    if (!endDateRange || !startDateRange) {
      alert(BasicLanguage.sensorDownload.dateRange[language]);
      return;
    }

    const startDate = moment(startDateRange).startOf("day").toDate();
    const endDate = moment(endDateRange).endOf("day").toDate();
    const selectedSensorIds = Object.keys(selectedSensors).filter(
      (id) => selectedSensors[id]
    );

    if (selectedSensorIds.length === 0) {
      alert(BasicLanguage.sensorDownload.selectSensor[language]);
      return;
    }

    if (moment(endDateRange).diff(moment(startDateRange), "day") > 31) {
      alert(BasicLanguage.sensorDownload.limitDays[language]);
      return;
    }

    setDownloading(true);
    const csvData = [];
    let hasBeaconData = false;

    if (selectedSensorIds.length > 0) {
      for (const sensorId of selectedSensorIds) {
        try {
          // Query for sensor data
          const sensorQuery = query(
            collection(
              db,
              ENV,
              VERSION,
              "facilities",
              facilityId,
              "sensorHistory"
            ),
            orderBy("createdAt", "desc"),
            where("deviceId", "==", sensorId),
            where("dataType", "==", "data"),
            where("createdAt", ">=", startDate),
            where("createdAt", "<=", endDate)
          );

          // Query for beacon data
          const beaconQuery = query(
            collection(
              db,
              ENV,
              VERSION,
              "facilities",
              facilityId,
              "sensorHistory"
            ),
            orderBy("createdAt", "desc"),
            where("deviceId", "==", sensorId),
            where("createdAt", ">=", startDate),
            where("createdAt", "<=", endDate)
          );

          // Process sensor data
          const sensorSnapshot = await getDocs(sensorQuery);
          const filteredSensorData = [];
          sensorSnapshot.forEach((doc) => {
            const docData = doc.data();
            if (docData.deviceType !== "webiot_ibeacon") {
              filteredSensorData.push(docData);
            }
          });

          filteredSensorData.forEach((sensorData) => {
            const formattedDate = moment(sensorData.createdAt.seconds * 1000)
              .tz(currentTimeZoneName)
              .format(BasicLanguage.sensorDownload.csv.long[language]);

            csvData.push(`${sensorId},${sensorData.value},${formattedDate},`);
          });

          // Process beacon data
          const beaconSnapshot = await getDocs(beaconQuery);
          const beaconSignals = [];
          beaconSnapshot.forEach((doc) => {
            beaconSignals.push(doc.data());
          });

          if (beaconSignals.length > 0) {
            hasBeaconData = true;
          }

          const beaconSessions = calculateBeaconSessions(beaconSignals);
          console.log(beaconSessions, "beaconSessions");
          beaconSessions.forEach((session) => {
            console.log(session.place, "this is the place value");
            const inTime = moment(session.inTime.seconds * 1000)
              .tz(currentTimeZoneName)
              .format(BasicLanguage.sensorDownload.csv.long[language]);
            const outTime = moment(session.outTime.seconds * 1000)
              .tz(currentTimeZoneName)
              .format(BasicLanguage.sensorDownload.csv.long[language]);
            csvData.push(
              `${session.deviceId},${
                session.place
              },${inTime},${outTime},${getNameByUid(
                facility,
                session.beaconHolder === "0" ? "-" : session.beaconHolder
              )}`
            );
          });
        } catch (error) {
          console.error(error);
        }
      }
    }

    // Adjust header dynamically based on beacon data
    const header = hasBeaconData
      ? `${BasicLanguage.sensorDownload.HeadingFormat.sensorId[language]},` +
        `${BasicLanguage.sensorDownload.HeadingFormat.value[language]},` +
        `${BasicLanguage.sensorDownload.HeadingFormat.date1[language]},` +
        `${BasicLanguage.sensorDownload.HeadingFormat.date2[language]},` +
        `${BasicLanguage.sensorDownload.HeadingFormat.inCharge[language]}`
      : `${BasicLanguage.sensorDownload.HeadingFormat.sensorId[language]},` +
        `${BasicLanguage.sensorDownload.HeadingFormat.value[language]},` +
        `${BasicLanguage.sensorDownload.HeadingFormat.date[language]}`;

    csvData.unshift(header);

    // Create and download the CSV
    if (csvData.length > 1) {
      const csvString = csvData.join("\n");
      const filename = `sensor_data_all_${moment()
        .tz(currentTimeZoneName)
        .format(BasicLanguage.sensorDownload.csv.short[language])}.csv`;
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      alert(BasicLanguage.common.date.datePicker.noSensorDataFound[language]);
    }

    setDownloading(false);
  };

  React.useEffect(() => {
    setSensors([]);
    setLoading(true);
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "sensors"),
        where("active", "==", true)
      )
    )
      .then((querysnapshot) => {
        setLoading(false);
        setSensors(
          querysnapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          })
        );
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      });
  }, [facilityId]);

  const downloadDisabled = React.useMemo(() => {
    return (
      !startDateRange ||
      !endDateRange ||
      startDateError ||
      endDateError ||
      downloading ||
      Object.values(selectedSensors).every((value) => !value)
    );
  }, [
    startDateRange,
    endDateRange,
    startDateError,
    endDateError,
    downloading,
    selectedSensors,
  ]);

  const indexOfLastSensor = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstSensor = indexOfLastSensor - ITEMS_PER_PAGE;
  const currentSensors = sensors.slice(indexOfFirstSensor, indexOfLastSensor);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Box component="main" sx={SBXStyles.mainContainer}>
          <Typography className="pageTitle" variant="h4">
            {BasicLanguage.common.menu.sensor[language]}
          </Typography>
          <Typography sx={{ mt: 2, mb: 2 }}>
            {BasicLanguage.sensor.description[language]}
          </Typography>

          <Box sx={SBXStyles.mainBox}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={6} size="large" colSpan={7}>
                    <Box display={"flex"} justifyContent={"end"} gap={2}>
                      <DateRangePicker
                        startDate={startDateRange}
                        setStartDate={setStartDateRange}
                        setEndDate={setEndDateRange}
                        endDate={endDateRange}
                        startDateError={startDateError}
                        setStartDateError={setStartDateError}
                        endDateError={endDateError}
                        setEndDateError={setEndDateError}
                      />
                      <Box
                        height={"100%"}
                        direction="row"
                        spacing={2}
                        justifyContent={"flex-end"}
                      >
                        <Button
                          variant="outlined"
                          fullWidth
                          sx={{ height: "100%" }}
                          disabled={downloadDisabled}
                          onClick={downloadDataByDateRange}
                        >
                          {BasicLanguage.common.download[language]}
                        </Button>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={selectAllSensors}
                      onChange={toggleSelectAllSensors}
                    />
                  </TableCell>
                  <TableCell>
                    {BasicLanguage.sensor.table.id[language]}
                  </TableCell>
                  <TableCell>
                    {BasicLanguage.sensor.table.type[language]}
                  </TableCell>
                  <TableCell>
                    {BasicLanguage.sensor.table.name[language]}
                  </TableCell>
                  <TableCell>
                    {BasicLanguage.sensor.table.battery[language]}
                  </TableCell>
                  <TableCell>
                    {BasicLanguage.sensor.table.value[language]}(
                    {BasicLanguage.sensor.table.lastUpdated[language]})
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableCell colSpan={5}>
                    <Box
                      sx={{
                        display: "flex",
                        height: 200,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  </TableCell>
                ) : (
                  <>
                    {currentSensors.map((sensorData, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            checked={selectedSensors[sensorData.id] || false}
                            onChange={() =>
                              toggleSensorSelection(sensorData.id)
                            }
                          />
                        </TableCell>
                        <TableCell>{sensorData.id}</TableCell>
                        <TableCell>{sensorData.type}</TableCell>
                        <TableCell>{sensorData.name}</TableCell>
                        <TableCell>
                          {sensorData.type !== "webiot_ibeacon" && (
                            <BatteryStatus
                              facilityId={facilityId}
                              sensorId={sensorData.id}
                              sensorType={sensorData.type}
                            />
                          )}
                          {sensorData.type === "webiot_ibeacon" && "-"}
                        </TableCell>
                        <TableCell>
                          <SensorCurrentValue
                            sensordata={sensorData}
                            facilityId={facilityId}
                            sensorId={sensorData.id}
                            sensorProcess={sensorData.process}
                            reloadButton={false}
                            language={language}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          <IconButton
                            component={Link}
                            to={"./" + sensorData.id}
                          >
                            <SettingsOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
                {!loading && sensors.length === 0 ? (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell colSpan={5}>
                      <Box
                        sx={{
                          width: "100%",
                          height: 200,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {BasicLanguage.sensor.none[language]}
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </Box>
          <Stack
            direction="row"
            justifyContent="center"
            gap={2}
            borderRadius={"100%"}
            mt={2}
          >
            <Button
              size="large"
              variant="contained"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1 || sensors?.length === 0}
            >
              <KeyboardArrowLeftIcon />
            </Button>

            <Button
              size="large"
              variant="contained"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={
                currentPage === Math.ceil(sensors?.length / ITEMS_PER_PAGE) ||
                sensors?.length === 0
              }
            >
              <KeyboardArrowRightIcon />
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
}
