import * as React from "react";
import {
  Typography,
  Button,
  MenuItem,
  InputLabel,
  Stack,
  CircularProgress,
} from "@mui/material";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import languageState from "../../recoil/atoms/languageState";
import Chip from "@mui/material/Chip";
import BasicLanguage from "../languages/Basic";
import { db } from "../utils/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  deleteDoc,
  where,
} from "firebase/firestore";

import { useParams } from "react-router";
import { useEffect, useState } from "react";
import SBXStyles from "../utils/Styles";
import sessionState from "../../recoil/atoms/sessionState";
import { useRecoilValue } from "recoil";
const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

export default function SensorRegister() {
  const { facilityId } = useParams();
  const [sensorNames, setSensorNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const session = useRecoilValue(sessionState);
  const language = useRecoilValue(languageState);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSensors(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(
          collection(db, ENV, VERSION, "facilities", facilityId, "sensors"),
          where("type", "in", [
            "una_motion",
            "webiot_contact",
            "una_px25a",
            "webiot_ibeacon",
          ]),
          where("active", "==", true)
        );
        const querySnapshot = await getDocs(q);
        const sensorNames = querySnapshot.docs.map((doc) => doc.id);
        setSensorNames(sensorNames);

        fetchDataPrev(sensorNames);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDataPrev = async () => {
      try {
        const docRef = doc(
          db,
          ENV,
          VERSION,
          "facilities",
          facilityId,
          "registeredSensors",
          facilityId
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const sensors = docSnap.data().sensors;
          setSelectedSensors(sensors);
        } else {
          setSelectedSensors([]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [facilityId]);

  const handleSave = async () => {
    try {
      const docRef = doc(
        db,
        ENV,
        VERSION,
        "facilities",
        facilityId,
        "registeredSensors",
        facilityId
      );
      setLoading(true);
      if (!session || !session.uid) {
        return;
      }

      if (selectedSensors.length > 5) {
        alert(
          BasicLanguage.facilitySettings.sensorRegister.sensorSelectLimit[
            language
          ]
        );
        setLoading(false);
        return;
      }

      const docSnap = await getDoc(docRef);

      const existingSensors = selectedSensors.filter((sensor) =>
        sensorNames.includes(sensor)
      );

      if (docSnap.exists()) {
        if (existingSensors.length === 0) {
          await deleteDoc(docRef);
        } else {
          await updateDoc(docRef, {
            sensors: existingSensors,
          });
        }
      } else {
        if (existingSensors.length > 0) {
          await setDoc(docRef, {
            sensors: existingSensors,
          });
        }
      }
      alert(
        BasicLanguage.facilitySettings.sensorRegister
          .sensorRegisterationComplete[language]
      );
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      {sensorNames.length <= 0 ? (
        <></>
      ) : (
        <Box sx={SBXStyles.mainBox}>
          <Stack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            gap={4}
          >
            <Typography className="" variant="h5">
              {BasicLanguage.facilitySettings.sensorRegister.title[language]}
            </Typography>

            <FormControl sx={{ width: 500 }}>
              <InputLabel id="multiple-chip-label">
                {BasicLanguage.common.menu.sensor[language]}
              </InputLabel>
              <Select
                labelId="multiple-chip-label"
                multiple
                value={selectedSensors}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label={BasicLanguage.common.menu.sensor[language]}
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {sensorNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box>
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={30} />
                ) : (
                  BasicLanguage.admin.common.save[language]
                )}
              </Button>
            </Box>
          </Stack>
        </Box>
      )}
    </>
  );
}
