import { useEffect, useRef } from "react";
import React from "react";
import chroma from "chroma-js";
import {
  CartesianGrid,
  Line,
  LineChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box } from "@mui/material";
import BasicLanguage from "../../components/languages/Basic";

import { BarChart, Bar } from "recharts";
import * as d3 from "d3";

const generateArc = (
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  cornerRadius
) => {
  return d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(startAngle)
    .endAngle(endAngle)
    .cornerRadius(cornerRadius);
};
const toRad = (deg) => {
  return (deg * Math.PI) / 180;
};
const toDeg = (rad) => {
  return (rad * 180) / Math.PI;
};

export const SbxPieChart = (props) => {
  const ref = useRef();

  const progress = props.progress > 0.02 ? props.progress : 0.02;

  const innerRadius = props.innerRadius ? props.innerRadius : 10;
  const outerRadius = props.outerRadius ? props.outerRadius : 50;
  const startAngle = props.startAngle ? props.startAngle : 0;
  const endAngle = props.endAngle ? props.endAngle : 360;
  const cornerRadius = props.cornerRadius ? props.cornerRadius : 10;
  const animDuration = props.animDuration ? props.animDuration : 1000;
  const animDelay = props.animDelay ? props.animDelay : 500;
  const animStartAngle = props.animStartAngle
    ? props.animStartAngle
    : startAngle;

  const rotate = props.rotate ? props.rotate : 0;

  const colour = props.colour;

  useEffect(() => {
    // const _progress = Math.random()

    const radEnd = (progress * endAngle * Math.PI) / 180;
    const radStart = (progress * startAngle * Math.PI) / 180;
    const radAnimStar = (animStartAngle * Math.PI) / 180;

    const interpolate = d3.interpolate(toRad(animStartAngle), radEnd);
    const arc0 = generateArc(
      innerRadius,
      outerRadius,
      toRad(startAngle),
      toRad(startAngle),
      cornerRadius
    );
    const arc = generateArc(
      innerRadius,
      outerRadius,
      toRad(startAngle),
      toRad(endAngle),
      cornerRadius
    );

    const svg = d3.select(ref.current);
    svg.selectAll("path").remove();
    svg
      .append("path")
      .attr("transform", "translate(75,75)")
      .attr("fill", "#EEEEEE")
      .attr(
        "d",
        generateArc(
          innerRadius,
          outerRadius,
          toRad(startAngle),
          toRad(endAngle),
          cornerRadius
        )
      )
      .attr(
        "style",
        `filter: drop-shadow(1px 1px 2px #bebebe) drop-shadow(-1px -1px 2px #ffffff)`
      );

    svg
      .append("path")
      .attr("transform", "translate(75,75)")
      .attr("fill", colour ? colour : getColor(progress))
      .attr("d", arc0)
      .transition()
      .duration(animDuration)
      .delay(animDelay)
      .ease(d3.easeBounce)
      .attrTween("d", function (d, i) {
        return function (t) {
          arc.endAngle(interpolate(t));
          return arc();
        };
      });
  }, [props]);

  return (
    <>
      <svg
        ref={ref}
        viewBox="0 0 150 150"
        style={{ transform: `rotate(${rotate}deg)` }}
      />
    </>
  );
};

export const SbxPieChart2 = (props) => {
  const ref = useRef();

  const value = props.value ? props.value : 0;
  const pastValue = props.pastValue ? props.pastValue : 0;
  const maxValue = props.maxValue ? props.maxValue : 100;
  const minValue = props.minValue ? props.minValue : 0;

  const innerRadius = props.innerRadius ? props.innerRadius : 10;
  const outerRadius = props.outerRadius ? props.outerRadius : 50;
  const startAngle = props.startAngle ? props.startAngle : 0;
  const endAngle = props.endAngle ? props.endAngle : 360;
  const cornerRadius = props.cornerRadius ? props.cornerRadius : 10;
  const animDuration = props.animDuration ? props.animDuration : 1000;
  const animDelay = props.animDelay ? props.animDelay : 500;
  const animStartAngle = props.animStartAngle
    ? props.animStartAngle
    : startAngle;

  const valueRange = Math.abs(startAngle - endAngle);
  const valuePercentage = (value - minValue) / Math.abs(maxValue - minValue);
  const needleDeg = valueRange * valuePercentage - endAngle;

  const valuePastPercentage =
    (pastValue - minValue) / Math.abs(maxValue - minValue);
  const pastNeedleDeg = valueRange * valuePastPercentage - endAngle;

  const rotate = props.rotate ? props.rotate : 0;

  const colour = props.colour;

  useEffect(() => {
    // const _progress = Math.random()

    const data = [1, 2, 3, 2];
    const dataTotal = data.reduce((sum, elm) => sum + elm, 0);

    const colours = [
      "rgba(255,45,45,1)",
      "rgba(253,228,21,1)",
      "rgba(55,192,0,1)",
      "rgba(80,174,234,1)",
    ];
    const pie = d3
      .pie()
      .sort((a, b) => d3.ascending(a.name, b.name))
      .startAngle(toRad(startAngle))
      .endAngle(toRad(endAngle));
    const arcs = pie(data);

    let valuePosition = (() => {
      let f = true;
      let index = 1;
      const p = valuePercentage ? valuePercentage : 0;
      while (f) {
        const currentTotalInLoop = data
          .slice(0, index)
          .reduce((sum, elm) => sum + elm, 0);
        if (p <= currentTotalInLoop / dataTotal) {
          f = false;
        } else {
          index++;
        }
        if (index > 1000) {
          f = false;
          alert("loop error");
        }
      }
      return index - 1;
    })();

    const svg = d3.select(ref.current);
    svg.selectAll("path").remove();
    svg.selectAll("g").remove();

    const backgroundGroup = svg.append("g");
    arcs.forEach((a, i) => {
      const arc = generateArc(
        innerRadius,
        outerRadius,
        a.startAngle,
        a.endAngle,
        cornerRadius
      );
      backgroundGroup
        .append("path")
        .attr("transform", "translate(75,75)")
        .attr("fill", "#FFFFFF")
        .attr("d", arc)
        .attr(
          "style",
          i == valuePosition
            ? `filter: drop-shadow(1px 1px 2px #bebebe) drop-shadow(-1px -1px 2px #ffffff)`
            : `filter: drop-shadow(.5px .5px 1px #dedede) drop-shadow(-0.5px -0.5px 1px #ffffff)`
        );
    });
    const chartGroup = svg.append("g");
    arcs.forEach((a, i) => {
      const arc = generateArc(
        innerRadius,
        outerRadius,
        a.startAngle,
        a.endAngle,
        cornerRadius
      );
      // const interpolate = d3.interpolate(a.startAngle, a.endAngle);
      // const interpolate = d3.interpolate(0, 1);

      chartGroup
        .append("path")
        .attr("transform", "translate(75,75)")
        .attr("fill", colours[i])
        .attr("d", arc)
        // .attr("stroke-width", i == valuePosition ? 1.0 : 0)
        // .attr("stroke", "gray")

        .style("opacity", i == valuePosition ? 1.0 : 0.3);
      // .transition()
      // .duration(animDuration)
      // .delay(animDelay)
      // .ease(d3.easeBounce)
      // .styleTween('opacity', function (d, i) {
      //     return function (t) {
      //         return t;
      //     };
      // })
    });

    var pathPoints = [
      { x: 75, y: 130 },
      { x: 75, y: 110 },
    ];
    var pathPoints2 = [
      { x: 74, y: 90 },
      { x: 76, y: 90 },
      { x: 75, y: 30 },
      { x: 74, y: 90 },
    ];

    var curveFunc = d3
      .line(d3.curveBasis)
      // .curve() // curveメソッドで線の形を変更
      .x(function (d) {
        return d.x;
      })
      .y(function (d) {
        return d.y;
      });

    const needle = svg.append("g");
    const interpolate = d3.interpolate(0, needleDeg);

    needle
      .append("circle")
      .attr("cy", 75)
      .attr("cx", 75)
      .attr("r", 3)
      .attr("fill", "#333");
    needle
      .append("path")
      .attr("d", curveFunc(pathPoints))
      .attr("stroke", "#rgba(255,255,255,0)")
      .attr("stroke-width", "1")
      .attr("fill", "none");

    needle
      .append("path")
      .attr("d", curveFunc(pathPoints2))
      .attr("stroke", "#333")
      .attr("stroke-width", "1")
      .attr("fill", "#333");
    needle
      .style("transform-origin", "center")
      .transition()
      .duration(animDuration)
      .delay(animDelay + animDuration)
      .ease(d3.easeBounce)
      .styleTween("transform", function (d, i) {
        return function (t) {
          return `rotate(${interpolate(t)}deg)`;
        };
      });

    var pastPathPoints = [
      { x: 75, y: 110 },
      { x: 75, y: 110 },
    ];
    var pastPathPoints2 = [
      { x: 74, y: 45 },
      { x: 76, y: 45 },
      { x: 75, y: 40 },
      { x: 74, y: 45 },
    ];

    const needlePast = svg.append("g");
    const interpolatePast = d3.interpolate(0, pastNeedleDeg);

    needlePast
      .append("path")
      .attr("d", curveFunc(pastPathPoints))
      .attr("stroke", "#rgba(255,255,255,0)")
      .attr("stroke-width", "1")
      .attr("fill", "none");

    needlePast
      .append("path")
      .attr("d", curveFunc(pastPathPoints2))
      .attr("stroke", "#ccc")
      .attr("stroke-width", "1")
      .attr("fill", "none");
    needlePast
      .style("transform-origin", "center")
      .style("transform", `rotate(${pastNeedleDeg}deg)`);
    // .transition()
    // .duration(0)
    // .duration(animDuration)
    // .delay(animDelay)
    // .ease(d3.easeBounce)
    // .styleTween('transform', function (d, i) {
    //     return function (t) {
    //         return `rotate(${interpolatePast(t)}deg)`;
    //     };

    // })
  }, [props]);

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box>
          <svg
            ref={ref}
            viewBox="0 0 150 150"
            style={{ transform: `rotate(${rotate}deg)` }}
          />
        </Box>
        {/* <Box sx={{ position: "absolute", bottom: 0, width: 100, height: 100 }}>
          <SbxLineChart
            history={[
              {
                date: "2024-01-13",
                value: Math.round(Math.random() * 200) - 100,
              },
              {
                date: "2024-01-01",
                value: Math.round(Math.random() * 200) - 100,
              },
              {
                date: "2023-11-22",
                value: Math.round(Math.random() * 200) - 100,
              },
              {
                date: "2023-10-10",
                value: Math.round(Math.random() * 200) - 100,
              },
              {
                date: "2023-09-12",
                value: Math.round(Math.random() * 200) - 100,
              },
            ]}
          />
        </Box> */}
      </Box>
    </>
  );
};
export const SbxPieChartMulti = (props) => {
  const ref = useRef();

  const progress = props.progress > 0.02 ? props.progress : 0.02;

  const innerRadius = props.innerRadius ? props.innerRadius : 10;
  const outerRadius = props.outerRadius ? props.outerRadius : 50;
  const startAngle = props.startAngle ? props.startAngle : 0;
  const endAngle = props.endAngle ? props.endAngle : 360;
  const cornerRadius = props.cornerRadius ? props.cornerRadius : 10;
  const animDuration = props.animDuration ? props.animDuration : 1000;
  const animDelay = props.animDelay ? props.animDelay : 500;

  const rotate = props.rotate ? props.rotate : 0;

  const data = props.data ? props.data : [3, 8, 13, 7];
  const colours = props.colors;
  const pie = d3.pie().startAngle(toRad(startAngle)).endAngle(toRad(endAngle));
  const arcs = pie(data);

  useEffect(() => {
    // const _progress = Math.random()

    const rad = (progress * endAngle * Math.PI) / 180;
    const arc = generateArc(
      innerRadius,
      outerRadius,
      toRad(startAngle),
      toRad(endAngle),
      cornerRadius
    );

    const svg = d3.select(ref.current);
    svg.selectAll("path").remove();
    svg
      .append("path")
      .attr("transform", "translate(75,75)")
      .attr("fill", "#DDDDDD")
      .attr(
        "d",
        generateArc(
          innerRadius,
          outerRadius,
          toRad(startAngle),
          toRad(endAngle),
          cornerRadius
        )
      )
      .attr(
        "style",
        `filter: drop-shadow(1px 1px 2px #bebebe) drop-shadow(-1px -1px 2px #ffffff)`
      );

    arcs.forEach((a, i) => {
      const arc = generateArc(
        innerRadius,
        outerRadius,
        a.startAngle,
        a.endAngle,
        cornerRadius
      );
      const interpolate = d3.interpolate(a.startAngle, a.endAngle);
      svg
        .append("path")
        .attr("transform", "translate(75,75)")
        .attr("fill", colours[i])
        .attr("d", arc)
        .transition()
        .duration(animDuration)
        .delay(animDelay)
        .ease(d3.easeBounce)
        .attrTween("d", function (d, i) {
          return function (t) {
            arc.endAngle(interpolate(t));
            return arc();
          };
        });
    });
  }, [props]);

  return (
    <>
      <svg
        ref={ref}
        viewBox="0 0 150 150"
        style={{ transform: `rotate(${rotate}deg)` }}
      />
    </>
  );
};

const getColor = (i) => {
  let code = "";
  const colours = chroma
    .scale(["#50AEEA", "#37C000", "#FDE415", "#FF2d2d"])
    .domain([1, 0]);
  code = colours(i).hex();
  return code;
};

export const SbxBarChartLib = (props) => {
  const data = props.data;
  const dataKey = props.dataKey || "m2";

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar
            dataKey={dataKey}
            fill="#50AEEA"
            activeBar={<Rectangle fill="pink" stroke="blue" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export const SbxStackedBarChart = (props) => {
  const data = props.data;
  const bottomDataKey = props.bottomDataKey;
  const topDataKey = props.topDataKey;
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar
            dataKey={bottomDataKey}
            stackId="requests"
            fill="#50AEEA"
            activeBar={<Rectangle fill="pink" stroke="blue" />}
          />
          <Bar
            dataKey={topDataKey}
            stackId="requests"
            fill="#bebebe"
            activeBar={<Rectangle fill="pink" stroke="blue" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

const CustomTooltip = ({
  active,
  payload,
  label,
  bottomDataKey,
  topDataKey,
  language,
}) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
          borderRadius: "5px",
          whiteSpace: "pre-line",
        }}
      >
        <p style={{ fontWeight: "bold", margin: 0 }}>{label}</p>
        <p style={{ margin: 0 }}>
          <strong>{bottomDataKey}:</strong> {data.totalHours}{" "}
          {BasicLanguage.dashboard.hours[language]}
        </p>
        <p style={{ margin: 0 }}>
          <strong>{topDataKey}:</strong>
          <br />
          {data.places.split(", ").join("\n")}{" "}
        </p>
      </div>
    );
  }

  return null;
};

export const SbxStackedBarChartPlace = (props) => {
  const data = props.data;
  const bottomDataKey = props.bottomDataKey;
  const topDataKey = props.topDataKey;
  const language = props.language;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={100}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip
          content={
            <CustomTooltip
              language={language}
              bottomDataKey={bottomDataKey}
              topDataKey={topDataKey}
            />
          }
        />
        <Bar
          dataKey="totalHours"
          fill="#50AEEA"
          name={`${bottomDataKey}`}
          stackId="a"
          activeBar={<Rectangle fill="pink" stroke="blue" />}
        />
        <Bar
          dataKey="places"
          fill="rgba(255, 255, 255, 0)"
          name={`${topDataKey}`}
          stackId="a"
          activeBar={<Rectangle />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
const CustomTooltipPlace = ({
  active,
  payload,
  label,
  bottomDataKey,
  topDataKey,
  language,
}) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
          borderRadius: "5px",
          whiteSpace: "pre-line",
        }}
      >
        <p style={{ fontWeight: "bold", margin: 0 }}>{label}</p>
        <p style={{ margin: 0 }}>
          <strong>{bottomDataKey}:</strong> {data.totalHours}{" "}
          {BasicLanguage.dashboard.hours[language]}
        </p>
        <p style={{ margin: 0 }}>
          <strong>{topDataKey}:</strong>
          <br />
          {data.Person.split(", ").join("\n")}
        </p>
      </div>
    );
  }

  return null;
};

export const SbxStackedBarChartPlacePerson = (props) => {
  const data = props.data;
  const bottomDataKey = props.bottomDataKey;
  const topDataKey = props.topDataKey;
  const language = props.language;
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={100}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip
          content={
            <CustomTooltipPlace
              language={language}
              bottomDataKey={bottomDataKey}
              topDataKey={topDataKey}
            />
          }
        />

        <Bar
          dataKey="totalHours"
          fill="#50AEEA"
          name="Working Hours"
          stackId="a"
          activeBar={<Rectangle fill="pink" stroke="blue" />}
        />
        <Bar
          dataKey="person"
          fill="#bebebe"
          name="person"
          stackId="a"
          activeBar={<Rectangle stroke="blue" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
export const SbxLineChart = (props) => {
  const ref = useRef();
  const containerRef = useRef();
  const padding = 0;

  let history = props.history ? props.history : [];

  useEffect(() => {
    const container = d3.select(containerRef.current);

    const svg = d3.select(ref.current);

    svg.selectAll("g").remove();
    svg.selectAll("path").remove();

    const width = 150;
    // グラフの高さ
    const height = 150;

    let timeparser = d3.timeParse("%Y-%m-%d");
    let format = d3.timeFormat("%Y/%m");
    history = history.map(function (d) {
      // 日付のデータをパース
      return { date: timeparser(d.date), value: d.value };
    });

    // X軸を時間のスケールに設定する
    const xScale = d3
      .scaleTime()
      // 最小値と最大値を指定しX軸の領域を設定する
      .domain([
        // データ内の日付の最小値を取得
        d3.min(history, function (d) {
          return d.date;
        }),
        // データ内の日付の最大値を取得
        d3.max(history, function (d) {
          return d.date;
        }),
      ])
      // SVG内でのX軸の位置の開始位置と終了位置を指定しX軸の幅を設定する
      .range([padding, width]);

    // Y軸を値のスケールに設定する
    const yScale = d3
      .scaleLinear()
      // 最小値と最大値を指定しX軸の領域を設定する
      .domain([
        // 0を最小値として設定
        d3.min(history, function (d) {
          return d.value;
        }),
        // データ内のvalueの最大値を取得
        d3.max(history, function (d) {
          return d.value;
        }),
      ])
      // SVG内でのY軸の位置の開始位置と終了位置を指定しY軸の幅を設定する
      .range([height, padding]);

    let color = d3.rgb("#85a7cc");
    // パス要素を追加
    const path = svg.append("path");
    //lineを生成
    const line = d3
      .line()
      // lineのX軸をセット
      .x(function (d) {
        return xScale(d.date);
      })
      // lineのY軸をセット
      .y(function (d) {
        return yScale(d.value);
      });
    path
      // dataをセット
      .datum(history)
      // 塗りつぶしをなしに
      .attr("fill", "none")
      // strokeカラーを設定
      .attr("stroke", color)
      // d属性を設定
      .attr("d", line);
  });
  return (
    <>
      <div ref={containerRef}>
        <svg ref={ref} viewBox="0 0 300 150" />
      </div>
    </>
  );
};

export const SbxBarChartLine = ({ data, dataKeys }) => {
  const colors = ["#7703D8", "#405CEE", "#2CA101", "#D8100E", "#E15F00"];
  const options = {
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart width={500} height={300} data={data} options={options}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {dataKeys?.map((key, i) => {
          return <Line type="monotone" dataKey={key} stroke={colors[i]} />;
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};
